import React from "react";
import {  
  Avatar,  
  Box,  
  Button,  
  Typography,  
  Container,  
} from "@mui/material";  
import ImageAvatar from '../../assets/icons/user_96px.png';

const Login =  ({handleSignIn,handleNavigatePage}) => {  
 

    const handleLogin = () => {  
      handleSignIn()
    };  
  
    return (  
      <Container maxWidth="xs">  
        <Box  
          sx={{  
            display : "flex",  
            flexDirection : "column",  
            alignItems : "center",  
            mt: 18, 
            mb:4, 
            p: 4,  
            boxShadow:0,  
            borderRadius:2,  
            bgcolor: "background.paper",  
          }}  
        > 
         <Typography variant="h5" gutterBottom>  
            Login To Continue
          </Typography>  
          {/* Avatar Icon */}  
          <Avatar sx={{ bgcolor: "secondary.main", mb: 2,mt:2,width:'100px',height:'100px' }}>  
          <img src={ImageAvatar}  id="avatar"  /* onError={imgonLoadError} */ alt="" className="userShoImg"/>
          </Avatar>  
  
          
  
  
          {/* Login Button */}  
          <Button  
            fullWidth  
            variant="contained"  
            color="primary"  
            sx={{ mt: 15,textTransform:'none' }}  
            onClick={handleLogin}  
          >  
            Login  
          </Button>  
  
          {/* Privacy Policy Button */}  
          <Button  
          onClick={()=>{handleNavigatePage(`/privacy`)}}
            fullWidth  
            variant="text"  
            color="secondary"  
            sx={{ mt: 5,textTransform:'none' }}  
          >  
            Privacy Policy  
          </Button>  
        </Box>  
      </Container>  
    );  
  };  
  
  export default Login;
  