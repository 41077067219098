import { useState,useEffect, useCallback} from 'react';
import './newstore.css'
import { Grid,Box,Select,MenuItem,TextField, Typography,Button,InputAdornment, CircularProgress, StepLabel, Step, Stepper} from '@mui/material';
import {ExpandLess,ExpandMore} from '@material-ui/icons'
import CheckboxList from './checkList/CheckboxList'
import storeThumbnail from '../../assets/icons/online_store_96px.png';
import {axiosClient,config} from '../../services/axiosClient';
import imageCompression from 'browser-image-compression';
import SearchableSelect from '../checkoutform/SearchableSelect';
import {Countries} from '../../assets/jsonData/countries'
import NavBarNavigation from '../navbarnavigation/NavBarNavigation';
import CustomTypography from '../customComponent/CustomTypography';
import CustomTextField from '../customComponent/CustomTextField';
import User from '../account/User';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

export default function NewStore({stores,setStores,handleSubmitStore, setOpenSnackBar, setMessage,t,setUser,getRecentProductsByscope,setUsercountryscope}) {
     console.log(stores)
    const history = useHistory()
    const [storeImage, setStoreImage] = useState();
    const userObj = JSON.parse(localStorage.getItem('user'));
    var   user =userObj.user;
    const [storeName, setStoreName] = useState('');
    const [storeTag, setStoreTag] = useState('');
    const [email, setEmail] = useState('');
    const[isCountriesloaded,setIscountriesLoaded]=useState(false)
    const [countries,setCountries]=useState([]);
   
   
    const [states,setStates]=useState([]);
    const [state,setState]=useState('');
    const [statelabel,setStateLabel]=useState('');
    const [cities,setCities]=useState([]);
    const [city,setCity]=useState('');
    const [citylabel,setCityLabel]=useState('');
    const [description, setDescription] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneCountry,setPhoneCountry] =useState('');
    const [countryPhoneCode,setCountryPhoneCode]=useState('+233')
    const [countryObj,setCountryObj]=useState({})

    const [currencyLabel,setCurrencyLabel]=useState('')
    const [isGroupLoaded,setIsGroupLoaded]=useState(false); 
    const [groupCategories,setGroupCategories]=useState([])
    const [checkedCategories,setCheckedCategories] =useState([])
    const [showCategoryList,setShowCategoryList]=useState(true)
    const [isTagAvailable,setIsTagAvailable]=useState(false)
    const [checking,setChecking]=useState(false)

    const steps = [  
      {  
        label: "Update Your Account",  
        description: "Update your account to continue",  
      },  
      {  
        label: "Create Your Store",  
        description: "Provide details about your store.",  
      },  
      {  
        label: "Done",  
        description: "Congratulations on Completing Registration ",  
      },  
    ];  
    const [activeStep, setActiveStep] = useState(user.address ? 1: 0);  
     
    const handleNext = () => {  
      setActiveStep((prevStep) => prevStep + 1);  
    };  
  
    const handleBack = () => {  
      setActiveStep((prevStep) => prevStep - 1);  
    };  

    const clearfields =()=>{
      setStoreName('');
      setDescription('')
      setPhone('');
      setEmail('');
      setCountryObj({});
      setState('');
      setCity('');
      setCheckedCategories([]);
      setCurrencyLabel('');
      document.getElementById("storeimg").src=storeThumbnail;
      setCheckedCategories([]);
    }
   
    /* const handleOnCategoryChange = (e)=>{
      var id=e.target.value
      setCategoryId(id)
      const cat=groupCategories.filter((item)=>item._id===id)
      console.log(cat[0])
      setCategory(cat[0]);
      console.log(cat[0]._id);
    } */
    const onSubmitStore =(e)=>{
      const form = e.currentTarget
      if (form.checkValidity() === false) {
        e.stopPropagation()
      }  
      e.preventDefault()

      const body={
        name :storeName,
        tag : storeTag,
        description : description,
        country: countryObj,
        state : statelabel,
        city : citylabel,
        email :email,
        phone : phoneCountry,
        userId: user._id,
        categories :checkedCategories,
      /*   currency: currencyLabel, */
        encodedimages: storeImage,
        status:"Verified",
        imageFolder:'stores'
      } 
      if(isTagAvailable){
        setMessage({body:'tag already taken choose different name' ,severity:'info'})
        setOpenSnackBar(true)
        return
      }
      if(isTagAvailable.length< 6){
        setMessage({body:'store name must be at least 6 characters long' ,severity:'info'})
        setOpenSnackBar(true)
        return
      }
        console.log(checkedCategories)
        if(checkedCategories.length <= 0){
          
        setMessage({body:'Select atleast one category for your store' ,severity:'info'})
        setOpenSnackBar(true)
        return
      }

      /*  if (!storeImage){
        setMessage({body:'Select your store Image' ,severity:'info'})
        setOpenSnackBar(true)
        return
       } */

        handleSubmitStore(body,clearfields)

    }
    /* const handleSubmit = (event) => {
      const form = event.currentTarget
      if (form.checkValidity() === false) {
        event.stopPropagation()
      }   
      
      event.preventDefault()
        
      UploadImageAndCreateStore(storeImage, user).then((response) => {
        console.log(response.data.store);
        if (response.status===200){
           localStorage.setItem('stores', JSON.stringify(response.data.stores));
          setStores(response.data.stores)

         
        


        clearfields();
        }else{
        
        }
        //addToast(exampleToast(response.data.message));
      })
    }
   */
    function blobToBase64(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    } 
    //perform Image Click action
     const performClick =() =>{
      const formfile = document.getElementById("file");
      formfile.click()
     }
    //Handle on formInput valu change change 
   async function onFileChange(e){
    var base64EncImage='';

      var file = e.target.files[0]
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 300,
        useWebWorker: true
      }  
       var fr= new FileReader();
         
            const compressedFile = await imageCompression(file, options);
            blobToBase64(compressedFile).then((base64image)=>{ //to do https://stackoverflow.com/questions/18650168/convert-blob-to-base64
             base64EncImage=base64image
            })
           fr.onload=(e)=>{
          
              document.getElementById('storeimg').src=e.target.result
              setStoreImage(base64EncImage);

           }
          try {
            fr.readAsDataURL(file)
          } catch (error) {
            
          }
    }
    
    const checkTag = async(tag) =>{
      const url=`${process.env.REACT_APP_SERVER_URL}/stores/tag/${tag}`
           setChecking(true)
     await  axiosClient.get(url,config).then((response)=>{
           setChecking(false)
          console.log(response.data.store)
            response.data.store === null ? setIsTagAvailable(false): setIsTagAvailable(true)
            
           })  
    }
  
   const onSrchCurrencyChange =(e)=>{
    const country=e.target.value;
    //const cid=countries[index].id;
    const name=country.currency;
    //console.log(`${JSON.stringify(countries[index])} ${countries[index].id} : currency ${countries[index].currency}`)
    setCurrencyLabel(name)
  
  }   
  /* const onCurrencyChange =(e)=>{
    const index=e.target.value;
    //const cid=countries[index].id;
    const name=countries[index].currency;
    
    console.log(`${JSON.stringify(countries[index])} ${countries[index].id} : currency ${countries[index].currency}`)
    setCurrencyLabel(name)
  
  } */
  const onStoreNameChange = (e)=>{
    var name = e.target.value
    setStoreName(name)
    var tag = name.replace(/ /g, '')
     setStoreTag(tag.toLowerCase()) 

     checkTag(tag.toLowerCase())
  }

  const onStoreTagChange = (e)=>{
    var name = e.target.value
    var tag = name.replace(/ /g, '')
    // console.log(tag.toLowerCase())
     setStoreTag(tag.toLowerCase()) 
     checkTag(tag.toLowerCase())
  }
  const onPhoneChange =(e)=>{
    setPhone(e.target.value)
    setPhoneCountry(`${countryPhoneCode}${e.target.value}`)
  // console.log(`${countryPhoneCode}${e.target.value}`)
}
 /*  const onCountryChange =(e)=>{
    const index=e.target.value;
    const cid=countries[index].id;
    //const name=countries[index].name;
    const countryObject={
          name:countries[index].name,
          countryCode:countries[index].iso2
    }
    
    console.log(countryObject)
    setCountryObj(countryObject)
    setCountryPhoneCode(`+${countries[index].phone_code}`)
    console.log(`${countries[index].name} ${countries[index].id}`)
    getStates(cid);
    //setShippingFees(parseFloat(0.00000020))
  } */

  const onSrchCountryChange =(e)=>{
    console.log(e.target)
    const country=e.target.value;
    const cid=country.id;
    //const name=country.name;
    const countryObject={
      name:country.name,
      countryCode:country.iso2
    }   
    setCountryObj(countryObject)

    setCountryPhoneCode(`+${country.phone_code}`)
    console.log(`${country.name} ${country.id}`)
   
    getStates(cid);
    //setShippingFees(parseFloat(0.00000020))
  }
  const onStateChange=(e)=>{
    const index=e.target.value;
    const sid=states[index].id;
    const name=states[index].name;
    console.log(`${states[index].name} ${states[index].id}`)
    setState(index);
    setStateLabel(name)
    getCities(sid);
  }
  const onInputStateChange=(e)=>{
    console.log(e.target.value)
    const name=e.target.value;
    setStateLabel(name);
  }
  const onInputCityChange=(e)=>{

    const name=e.target.value;
    setCityLabel(name);
  }
  const onCityChange=(e)=>{
    const index=e.target.value;
    //const cid=cities[index].id;
    const name=cities[index].name;
    console.log(`${cities[index].name} ${cities[index].id}`)
    setCity(index);
    setCityLabel(name)
  }
 
  const getStates= async (cid)=>{
        // const mstates= States.filter((s)=>s.country_id===cid)
    const url=`${process.env.REACT_APP_SERVER_URL}/states/${cid}`;
    await axiosClient.get(url,config).then((response)=>{
           console.log(response.data.states)
          setStates(response.data.states);

    })
 }
 const getCities= async (sid)=>{
        //const mstates= States.filter((s)=>s.country_id===cid)
  //setCities(response.data.cities);
  const url=`${process.env.REACT_APP_SERVER_URL}/cities/${sid}`;
  await axiosClient.get(url,config).then((response)=>{
        console.log(response.data.cities)
        setCities(response.data.cities);

  })
}
   const getCountries =  useCallback( async() => {

      // if (!mountedRef.current) return null ;

     if (!isCountriesloaded){
      try{
       // console.log(Countries)
        setCountries(Countries)
       /*  const url=`${process.env.REACT_APP_SERVER_URL}/countries`;
        await axiosClient.get(url,config).then((response)=>
              setCountries(Countries)
             
        ) */
        // console.log(mountedRef.current)
        
        }catch(err){
            console.log(err)
        }
     }
    },[isCountriesloaded]);
    
    useEffect(()=>{
    /*   function blobToDataURL(blob, callback) {
        var a = new FileReader();
        a.onload = function (e) {
          callback(e.target.result);
        };
        a.readAsDataURL(blob);
    }
const getImageFromStorage = async () => {

    try {
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/s3/Dalir23.png`, {
          responseType: 'blob',
        })
        .then((response) => {
          console.log(response)
          blobToDataURL(response.data, function (dataurl) {
          setImgFromStorage(dataurl); 
          });
        });
    } catch (err) {
      console.error(err);
    }
  }; */
      const handlegetGroup =()=>{
          const url=`${process.env.REACT_APP_SERVER_URL}/category/group`
  
           axiosClient.get(url,config).then((response)=>{
              console.log(response.data);
              setGroupCategories(response.data);
          })  
      }
     
      if (!isGroupLoaded){
          handlegetGroup();
          //getImageFromStorage()
      }
      if(!isCountriesloaded){
              getCountries();
      }


    
      return ()=>{
          setIsGroupLoaded(true);
          setIscountriesLoaded(true)

      }
  })
  return (
    <div className="newstore"> 
       <NavBarNavigation color='#000' title={t('form.store.newtitle')}/>
 <Grid container justifyContent='space-between' alignItems={'center'} direction='column' spacing={0}  >
                   
                {stores ? '' :<Grid item >
                 <CustomTypography variant='body2' color='GrayText'>{t('form.store.pagedescription')}</CustomTypography>
                </Grid>}

              </Grid>
       <div className="addnewStoreTitleContiner">
             
                {/*  <Link to="/dashboard/stores">
                 <button className="storeListButton">Stores</button>
              </Link> */}
             </div>
       <div className='client-page-content'>
          { 
           stores && stores.length  ? <Grid container justifyContent='center' alignItems={'center'} direction={'column'}padding={5} spacing={2} marginTop={'30vh'}>
                <Grid item xs={12} >
                 <CustomTypography>{t('form.store.store_alreadycreated')}</CustomTypography>
                 
                </Grid>
                <Grid item xs={12}>
                 <Button variant='outlined'sx={{textTransform:'none'}} onClick={()=>{history.push('/new-product')}}>{t("form.product.createnewproduct")}</Button>
                </Grid>
            </Grid>:
             <Box sx={{ width: "100%", maxWidth: 600, mx: "auto", mt: 4 }}>  
              {/*  <Stepper activeStep={activeStep} alternativeLabel>  
                 {steps.map((step, index) => (  
                   <Step key={index}>  
                     <StepLabel>{step.label}</StepLabel>  
                   </Step>  
                 ))}  
               </Stepper>   */}
         
               <Box sx={{ mt: 4 }}>  
                 <Grid container justifyContent={'space-between'} sx={{paddingLeft:2}}>
                 <Grid item >
                  <Typography variant="h6">{steps[activeStep].label}</Typography>  
                 <Typography variant="body2" color="textSecondary">  
                   {steps[activeStep].description}  
                 </Typography> 
                 </Grid>
                 </Grid> 
         
                 {/* Step 1: User Information Form */}  
                 {activeStep === 0 && (  
                   <Box sx={{ mt: 2 }}>  
                      <User user={user} t={t}setMessage={setMessage} setUser={setUser} setOpenSnackBar={setOpenSnackBar} getRecentProductsByscope={getRecentProductsByscope} setUsercountryscope={setUsercountryscope} handleNext={handleNext}/>
                   </Box>  
                 )}  
         
                 {/* Step 2: Store Information Form */}  
                 {activeStep === 1 && (  
                   <Box sx={{ mt: 2 }}>  
                    
                    <div className="client-newStoreFormContainer">
                     <form action="" className="newStoreForm" onSubmit={onSubmitStore}>
                    <Grid container justifyContent='space-between' >
                    <Grid item xs={12} sm={12} md={6} lg={6} onClick={()=>{performClick()}}>
                      <div className="newStoreUpload" >
                                <img src={storeThumbnail} alt="" className="newStoreUploadImg"  id="storeimg"/>
                               {/* <label htmlFor="file"> <Publish className="newStoreUploadIcon"/> Click to upload store logo </label>*/}
                                    <input type="file" id="file" style={{display:"none"}}  onChange={onFileChange}/>
                           </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={8} lg={8} >
                          <Grid container justifyContent='space-between' direction='column' spacing={2}>
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                    <div className="newStoreFormItem-column">
                        <label>{t('form.store.name.label')}</label>
                        <CustomTextField variant='outlined' type="text" name="name" placeholder="Naeate Computers" value={storeName} onChange={(e)=>{onStoreNameChange(e)}} required fullWidth/>
                    </div>
                
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                    <div className="newStoreFormItem-column">
                        <label>{t('form.store.tag.label')}</label>
                        <CustomTextField variant='outlined' type="text" name="tag" placeholder={t('form.store.tag.placeholder')} value={storeTag} onChange={(e)=>{onStoreTagChange(e)}} required fullWidth={false}/>
                         <Grid container justifyContent={'flex-start'}>
                            <Grid item>
                            <Grid container justifyContent={'flex-start'} spacing={0} marginTop={1}>
                          <Grid item>   <CustomTypography variant='body2'>{`${process.env.REACT_APP_WEBSITE_URL}/s/`}</CustomTypography></Grid>
                          <Grid item>   <CustomTypography variant='body2' color={isTagAvailable ?'red' : 'green'}>{`${storeTag}`}</CustomTypography></Grid>
                        </Grid>
                            </Grid>
                            <Grid item >
                              <Grid justifyContent={'center'} alignItems={'center'}>
                                <Grid item>
                                {checking ?  <CircularProgress  variant='indeterminate' color ='primary' size={14} />:''}
                                </Grid>
                              </Grid>
                            </Grid>
         
                         </Grid>
                        
                    </div>
                      
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                     <div className="newStoreFormItem-column">
                        <label>{t('form.store.description.label')}</label>
                        <CustomTextField variant='outlined'  multiline={true} minRows={2}  maxRows={3} type="text" name="description" rows="5" placeholder={t("form.store.description.hint")} value={description} onChange={(e)=>{setDescription(e.target.value)}} required fullWidth/>
                    </div> 
                    </Grid>{/**/}
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                   {/*  <div className="newStoreFormItem">
                        <label>Store Category</label>
                        <select type="text" name="category" value={categoryid} onChange={(e)=>{handleOnCategoryChange(e)}} required >
                        <option value="none" required></option>
                  {  
                   groupCategories.map((group,index)=>{
                    return <option key={index} value={group._id}>{group.name}</option>
                   })
                
                  
                  }
                   
                        </select>
                    </div>  */} 
                    </Grid>
         
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                    <div className="newStoreFormItem-column ">
                      <Grid container justifyContent='space-between'>
                       <Grid item > <CustomTypography>{t('form.store.category.label')}</CustomTypography> </Grid>
                       <Grid item >
                         {
                          showCategoryList ? <ExpandLess onClick={()=>{setShowCategoryList(!showCategoryList)}}/> : <ExpandMore onClick={()=>{setShowCategoryList(!showCategoryList)}}/>
                         }
                       </Grid>
         
                      </Grid>
                          {
                            showCategoryList ?  <div className='checkList'>
                            <CheckboxList required={true}  data={groupCategories} checkedCategories={checkedCategories} setCheckedCategories={setCheckedCategories} />
                           </div>
                      :''    
                          }  
         
                    </div> 
                    </Grid>
          
                        <Grid item xs={12} sm={12}>    
                    <div>
                        <label>{`${t('form.store.country')}:`}</label>
                      {/*   <Select value={country} variant='outlined' name="country"  required  onChange={onCountryChange} fullWidth>
                        {countries.map((c,index)=>(
                           <MenuItem key={c.id} value={index}>{c.name}</MenuItem>
                       ))}
                       </Select>  */}
                        <SearchableSelect required setOpen={()=>{}}  allOptions={countries} onChange={onSrchCountryChange} Label='Country'/>     
                    </div>
                    
                    </Grid>
                  {/*   <Grid item xs={12} sm={12}>    
                    <div className="newStoreFormItem">
                        <label>{`${t('form.store.currency')}:`}</label>
                    
                       <SearchableCurrencySelect  allOptions={countries} onChange={onSrchCurrencyChange} Label='Currency'/>
                    </div>
                   
                    </Grid> */}
         
                      
                   <Grid item xs={12} sm={12}>    
                   <div>
                        <label>{`${t('form.store.email')}:`}</label>
                        <CustomTextField variant='outlined' type="email" name="email" required placeholder={t("form.account.email.placeholder")}value={email} onChange={(e)=>{setEmail(e.target.value)}} fullWidth/>
                    </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                    <div>
                        <label>{`${t('form.store.phone')}:`}</label>
                       
         
                        <CustomTextField variant='outlined' type="number" maximum={13} name="phone" placeholder={t("form.account.phone.placeholder")} value={phone} required onChange={(e)=>{onPhoneChange(e)}}  InputProps={ {
                  startAdornment: <InputAdornment position="start">{countryPhoneCode}</InputAdornment>
                }} fullWidth/>
                    </div>
                    
                    </Grid>
         
                        
                
                    <Grid item xs={12} sm={12}>    
                     <div>
                        <label>{`${t('form.store.state')}:`}</label>
                       <>{
                        states.length >0 ? <Select value={state} variant='outlined'  onChange={onStateChange} required fullWidth 
                        sx={{  
                         "& .MuiOutlinedInput-root": {  
                           padding: "4px", // Outer padding adjustment  
                         },  
                         "& .MuiInputBase-input": {  
                           padding: "8px", // Inner padding adjustment  
                           fontSize: "12px", // Adjust font size  
                         },  
                       }} >
                        {states.map((s,index)=>(
                           <MenuItem key={s.id} value={index}>
                                {s.name}
                           </MenuItem>
                       ))}
                       </Select>: <CustomTextField variant='outlined'  placeholder={t('form.account.state.placeholder')} onChange={onInputStateChange} fullWidth />
                        }
                      </>
                       
                    </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>    
                        <div>
                    <label>{`${t('form.store.city')}:`}</label>
                       {cities.length > 0 ?  <Select value={city} variant='outlined' fullWidth onChange={onCityChange} required
                        sx={{  
                         "& .MuiOutlinedInput-root": {  
                           padding: "4px", // Outer padding adjustment  
                         },  
                         "& .MuiInputBase-input": {  
                           padding: "8px", // Inner padding adjustment  
                           fontSize: "12px", // Adjust font size  
                         },  
                       }} 
                        >
                       {cities.map((s,index)=>(
                           <MenuItem key={s.id} value={index}>
                                {s.name}
                           </MenuItem>
                       ))}
                       </Select> :  <CustomTextField variant='outlined' placeholder={t('form.account.city.placeholder')} onChange={onInputCityChange} fullWidth/>
                      }
                    </div>
                       </Grid>
                      
                    
                      <Grid item xs={12} sm={12} md={6} lg={6} marginBottom={2} marginTop={4}>
                      <Button type='submit' variant='outlined' color='primary' fullWidth>{`${t('form.store.buttoncreate')}`}</Button>
                      </Grid>
                  
                          </Grid>
                      </Grid>
         
                     {/*  <Grid item xs={12} sm={12} md={6} lg={6}>
         
                      </Grid> */}
                    </Grid>
                 
                    </form>
                   </div> 
         
                   </Box>  
                 )}  
         
                 {/* Navigation Buttons 
                 <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>  
                   <Button  
                     disabled={activeStep === 0}  
                     onClick={handleBack}  
                     variant="outlined"  
                   >  
                     Back  
                   </Button>  
                   <Button  
                     onClick={handleNext}  
                     variant="contained"  
                     color="primary"  
                   >  
                     {activeStep === steps.length - 1 ? "Finish" : "Next"}  
                   </Button>  
                 </Box>  */}  
               </Box>  
             </Box> 
                   
          }
          </div>
    </div>
  )
}
