import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    marginTop:10,
    marginBottom:30,
    padding:'0% 25%',
    
    [theme.breakpoints.down('xs')]:{
      backgroundColor:"#fff",
      marginTop:0,
       marginBottom:0,
    /*   padding:'1%', */
    paddingLeft:'16px',
    paddingRight:'16px',
      border:'0px solid',
      height:'88vh'
    },
  },
  content:{
    backgroundColor:'white',
  },
  title: {
    marginTop: '2%',
  },
  link: {
    textDecoration: 'none',
    color:'var(--app-primary)'
  },
  commentsTitle:{
    display:'flex',
    justifyContent:'space-between',
   
  }, 
   ordercard:{
      border:'0px solid',
      display:'flex',
      height:'80px',
      width:'24%',
      marginLeft:'1%'     
   },
   media:{
    height:80,
    width:'100%', 
  },
  layout: {
    marginTop: '5%',
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down(600 + theme.spacing(2) * 2)]: {
      width: '98%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginTop: 60,
    },
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  divider: {
    margin: '20px 0',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
 
}));
