/* eslint-disable no-unused-vars */
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {Button,Card,CardMedia} from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import useStyles from './styles';
import { myCld } from '../../../cloudinaryImage';

import slide0 from '../../../assets/slides/0.png'
import slide1 from '../../../assets/slides/1.png'
import slide2 from '../../../assets/slides/2.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


/* const images = [
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath:
      'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Bird',
    imgPath:
      'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Bali, Indonesia',
    imgPath:
      'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
  },
  {
    label: 'Goč, Serbia',
    imgPath:
      'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
  },
]; */

function StepperSlider({images,user,stores}) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;


  let history = useHistory();

   const links=[
  {image:slide0,link:'/new-store'},
 /*  {image:slide1,link:''},
  {image:slide2,link:''} */
  ]
   const handleNavigate = (link)=>{
  try{
    if (user){
      if (link!==''){
        history.push(link)
       }
     }else{
      history.push('/account')
     }
  }catch(err){
   console.log(err)
  }
}
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const classes= useStyles();
  return (
    <Box className={classes.sliderRoot}>
     {/*  <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 2,
          bgcolor: 'background.default',
          border:'1px solid '
          
        }}
      >
        <Typography>{images[activeStep].label}</Typography>
       </Paper>*/}
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        
      >
        {links.map((image, index) => (
          <div key={image.public_id}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Card onClick={()=>{handleNavigate(image.link)}}>
              <CardMedia onClick={()=>{handleNavigate(image.link)}} className={classes.cardImage} image={image.image}   /* image={`${ myCld.image(image.public_id).format('auto').quality('auto').addFlag('lossy').toURL() }`}  image={step.secure_url} image={`${image}`}  title={image.puclic_id}*//>
          </Card>   
            ) : null}
          </div>
        ))}

      </AutoPlaySwipeableViews>
   {/*    <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        style={{justifyContent:'center'}}
        
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      /> */}
    </Box>
  );
}

export default StepperSlider;
