/* eslint-disable no-unused-vars */
import './store.css';
import { useEffect,useState,useCallback } from 'react';
import { LocationSearching, MailOutline, PhoneAndroid, Publish,Edit,Money } from '@material-ui/icons';
import CheckboxList from './checkList/CheckboxList'

import { Select,MenuItem,Grid,Button, Typography,TextField} from '@mui/material';
import {axiosClient,config}from '../../services/axiosClient';
import {Daabia,store} from  '../../daabia/daabia';
import {StoreCard}    from  '../../components';
import ContentTitleBar from '../../components/contentitlebar/ContentTitleBar';
import storeThumbnail from '../../assets/icons/online_store_96px.png';
import { myCld } from '../../cloudinaryImage';
import imageCompression from 'browser-image-compression';
import NavBarNavigation from '../navbarnavigation/NavBarNavigation';
import { ContentCopy } from '@mui/icons-material';
import currency_Rates from '../../currencyRates.json'
import { NamedTransformation } from '@cloudinary/url-gen/actions';

export default function MyStore({_store,sales,setStore,setMessage,setOpenSnackBar,accessToken,t}) {
 console.log(_store.currency)
    /*  const [_store,setStore]=useState(JSON.parse(localStorage.getItem('store'))); */
          new Daabia(_store._id)
              /* analytics.salesById( '617830f1391d5d0cd0c7af41' );*/
     const [name,setName]=useState(_store.name)
     const [email,setEmail]=useState(_store.email)
     const [description,setDescription]=useState(_store.description)
     const [phone,setPhone]=useState(_store.phone)
     const [country,setCountry]=useState(_store.country)
     const [state,setState]=useState(_store.state)
     const [city,setCity]=useState(_store.city)
     const [currency,setCurrency]=useState(_store.currency);
     const[iscopied,setIscopied]=useState(false)
   /*   const[storeUrl]=useState(`${process.env.REACT_APP_CLIENT_ORIGIN}/s/${_store.tag}`) */
   const[storeUrl]=useState(`https://www.daabiamall.com/s/${_store.tag}`)
     console.log(storeUrl)
     const [groupCategories,setGroupCategories]=useState([])
     const [storeUpdated,setStoreUpdated]=useState(false);
     const [onStoreImageChange,setOnstoreImageChange]=useState(false);
     const [image,setImage]=useState(null);
     const[isCountriesloaded,setIscountriesLoaded]=useState(false);
     const [isGroupLoaded,setIsGroupLoaded]=useState(false); 
     const [countries,setCountries]=useState([]);
     console.log(currency_Rates)
     const [rates]=useState(currency_Rates.rates)
     const [currencyLabel,setCurrencyLabel]=useState('');
     const [imageurl,setImageUrl]=useState('');
     const userObj = JSON.parse(localStorage.getItem('user'));
     const [checkedCategories,setCheckedCategories] =useState([]);
     const [showEditCat,setShowEditCat]=useState(false);
     const onEditClick =()=>{
      setShowEditCat(!showEditCat)
     }
   /*   //var   user = userObj.user;
     function onFileInputChange(e) {
        var file = e.target.files[0];
        var reader = new FileReader();
        reader.onloadend = function (e) {
            document.getElementById('store-image').src = e.target.result
            document.getElementById('store-show-img').src = e.target.result
            setOnstoreImageChange(!onStoreImageChange);
         console.log(file)
         setImage(file);
        };

        try {
            reader.readAsDataURL(file)

        } catch (error) {
            console.log({ readAsDataURLError: error })
        }
    } */
    const performClick =() =>{
      const formfile = document.getElementById("file");
      formfile.click()
     }
    function blobToBase64(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    } 
    async function  onFileInputChange  (e) {
     /*  var progress = document.getElementById(`image-load-progress${shownProgressId}`) 
          progress.style.display ='block'*/
      var file = e.target.files[0];
      var reader = new FileReader();
      var base64EncImage='';
      const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 420,
          useWebWorker: true
        }        
        
        const compressedFile = await imageCompression(file, options);
                     blobToBase64(compressedFile).then((base64image)=>{ //to do https://stackoverflow.com/questions/18650168/convert-blob-to-base64
                      base64EncImage=base64image
                       console.log(base64EncImage)
                     })

      reader.onloadend = function (e) {
              
              // progress.style.display ='none'
              setOnstoreImageChange(!onStoreImageChange);

               //document.getElementById('store-image').src = e.target.result
            document.getElementById('store-show-img').src = e.target.result
          setImage(base64EncImage)

      };
      try {
          reader.readAsDataURL(file)

      } catch (error) {
          console.log({ readAsDataURLError: error })
      }
  }
    const onCurrencyChange =(e)=>{
      const name=e.target.value;
      //const cid=countries[index].id;
    /*   const name=countries[index].currency;
      console.log(`${countries[index].currency} ${countries[index].id} : currency ${countries[index].currency}`) 
      const name=countries[index].name;*/
      console.log(name)
     if(name){
      const rate =  name ? rates.filter((r) => r.name === name):rates[0]

      setCurrency(rate.name)
      console.log(rate)
     }
    
    }
    const findIndexOfObject = (array,nameofItem)=>{
      // Find Radagast
     return array.findIndex(function (item) {
      console.log(item.name === nameofItem)
   return  item.name === nameofItem; //item.currency === nameofItem;

 });
}


    
   /*  const handleOnCategoryChange = (e)=>{
      var id=e.target.value
      setCategoryId(id)
      const cat=groupCategories.filter((item)=>item._id===id)
      console.log(cat[0])
      setCategory(cat[0]);
      console.log(cat[0]._id);
    } */
    const handleUpdate=(e)=>{
      e.preventDefault();
      updateStore().then((response)=>{
          if(response.status===200){
            setStore(response.data)
               setName(response.data.name)
               setDescription(response.data.description);
               setPhone(response.data.phone);
               setEmail(response.data.email);
               setCountry(response.data.country);
               setState(response.data.state);
               setCity(response.data.city);
               setCurrency(response.data.currency)

              setMessage({body:'update successfully',severity:'success'})
              setOpenSnackBar(true)
              /***/if (onStoreImageChange){

                  UploadStoreImage(image).then((response) => {
                      if (response.data.status===200){
                        setMessage({body:'store image updated ',severity:'success'})
                        setOpenSnackBar(true) 
                      }
                      //addToast(exampleToast(response.data.message));
                    })
              } 
              setStoreUpdated(!storeUpdated);
             
         }else{
          setMessage({body:'update unsuccessful',severity:'error'})
          setOpenSnackBar(true)
          
         }
         

      });
}

     const handleUpdateStore= async(e)=>{

      const body={
        storeid:_store._id,
        name:name,
        email:email,
        phone:phone,
        description:description,
        country:country,
        state:state,
        city:city,
        checkedCategories:checkedCategories,
        currency:currency,
        auth_token:userObj.auth_token
      }
        e.preventDefault();
       await store.edit(body).then((response)=>{
            if(response.status===200){
              setStore(response.data)
                 setName(response.data.name)
                 setDescription(response.data.description);
                 setPhone(response.data.phone);
                 setEmail(response.data.email);
                 setCountry(response.data.country);
                 setState(response.data.state);
                 setCity(response.data.city);
                 setCurrency(response.data.currency)
                 setMessage({body:'update successful',severity:'success'})
                 setOpenSnackBar(true)

                  if (onStoreImageChange){

                    UploadStoreImage(image).then((response) => {
                        if (response.data.status===200){
                          setMessage({body:'store image update successful',severity:'success'})
                           setOpenSnackBar(true)
                        }
                        //addToast(exampleToast(response.data.message));
                      })
                } 
                setStoreUpdated(!storeUpdated);
               
           }else{
            setMessage({body:'update  unsuccessfully',severity:'success'})
            setOpenSnackBar(true)
           }
           

        });
  }

  const UploadStoreImage = (file) => {
    //const url = process.env.STORE_URL;

   /*  const formData = new FormData();
      formData.append('public_id',_store.image.public_id);
      formData.append('image', file);
    

      const  options={
        headers:{
          Authorization:`Bearer ${accessToken}`
        },

    } */
     const body={
      public_id:_store.image.public_id,
      image: file
     }
    return axiosClient.post(`${process.env.REACT_APP_SERVER_URL}/stores/updateImage/${_store._id}`, body, config)
  }

  const updateStore =()=>{
     
    const url = `${process.env.REACT_APP_SERVER_URL}/stores/${_store._id}`;
   
    const formData=new FormData();
          formData.append("storeId",_store._id);
          formData.append("name",name);
          formData.append("email",email);
          formData.append("phone",phone);
          formData.append("description",description);
          formData.append("country",country);
          formData.append("state",state);
          formData.append("city",city);
          formData.append("image",image);
          formData.append("categories",JSON.stringify(checkedCategories));

    const body={
             storeId:_store._id,
             name:name,
             email:email,
             phone:phone,
             description:description,
             country:country,
             state:state,
             city:city,
             currency:currency,
             categories:checkedCategories

    }
    const config = {
        headers: {
            'Content-Type':'application/json',
            'auth-token':userObj.auth_token,
          },
         
          
    }
    return axiosClient.patch(url, body,config)
  
  };
  const getCountries =  useCallback( async() => {

    // if (!mountedRef.current) return null ;

   if (!isCountriesloaded){
    try{
      const url=`${process.env.REACT_APP_SERVER_URL}/countries`;
      await axiosClient.get(url,{},config).then((response)=>{
            setCountries(response.data.countries)
            let currencyCode = findIndexOfObject(response.data.countries,'GHS')
            //setCurrency(currencyCode)      
        })
            
      
      // console.log(mountedRef.current)
      
      }catch(err){
          console.log(err)
      }
   }
  },[isCountriesloaded]);
    useEffect(()=>{
       try{

      if(rates){
        let c =  _store.currency ? rates.filter((r) => r.name === _store.currency):rates[0]
        setCurrency(c[0].name)
        console.log(c[0].name)
      }
/*    setImageUrl(_store.image.secure_url)
 */     setImageUrl(myCld.image(`${_store.image.public_id}`).format('auto').quality('auto').addFlag('lossy').toURL())
       }catch(err){
        console.log(err)
       }

        const fetchStore = async () => { //get specific Store
         try {
          const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/stores/${_store._id}`);
          const data = await res.json();
          return data;
         } catch (error) {
           console.log({fetch_store_message:error})
         }
        }
        const getStore = async () => {
            try {
             const storefromServer = await fetchStore();
             //console.log(storefromServer)
            if (storefromServer.length >0){
                setStore(storefromServer)

            }
        
            } catch (error) {
              console.log({message:error})
            }
           };
           const handlegetGroup =async ()=>{
            const url=`${process.env.REACT_APP_SERVER_URL}/category/group`
    
           await axiosClient.get(url,{},config).then((response)=>{
                //console.log(response.data)
                setGroupCategories(response.data);
                setCheckedCategories(_store.categories)

            })  
        }
       
        if (!isGroupLoaded){
            handlegetGroup();
        }
      

        getStore();
          if(!isCountriesloaded){
            getCountries()
          }
          return ()=>{
            setIscountriesLoaded(true)
            setIsGroupLoaded(true)
          }            

    },[getCountries, isCountriesloaded, isGroupLoaded, _store, setStore]);
  return (
    <div className="mystore">
      <NavBarNavigation title={''}  />
      {/* <div className="updateStoreTitle">
            <div className="storeTitleContainer">
                <h1 className="storeTitle">Edit Store</h1>
                <Link to="/dashboard/newStore">
                 <button className="storeAddButton">Create</button>
              </Link>
            </div>
       </div> */}
        {/* <StoreCard store={_store} sales={sales}/>
           <div className='storeCurrencyLabel'>
           <span>{storename}</span>
            <span>{` Local currency: ${store.currency}`}</span>
           </div>
            
            <ContentTitleBar store={_store} pagetitle={'Product'} buttons={ store.status==='Verified' ? [{title:'Add New',link:`/new-product?storeId=${_store._id}&storeName=${_store.storename}`},{title:'Products',link:`/product-list?storeId=${_store._id}&storeName=${_store.name}`}]:[]}/> */}

       <Grid container justifyContent='space-between' marginTop={2} padding={2}>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <Grid container justifyContent={'flex-start' } spacing={4}>
                      <Grid item xs={2} padding={0} onClick={()=>{performClick()}}>
                      <img onClick={()=>{performClick()}} src={`${imageurl!==''? imageurl :storeThumbnail}` } id="store-show-img" alt="" className="storeShowImg" />
                      
                      </Grid>

                      <Grid item xs={10} border={0} padding={1}>
                        <Grid container justifyContent={'flex-start'} direction={'column'}>
                            <Grid item >
                           
                           <Typography variant='h5' color='primary' fontWeight={600} align='left' >{name}</Typography>
                          
                            </Grid>
                            <Grid item >
                          
                       <Grid container justifyContent={'flex-start'}>
            <Grid item xs={12} >
              <Grid container justifyContent={'space-between'} alignItems={'center'} alignContent={'center'}>
               {/*  <Grid item onClick={() => { navigator.clipboard.writeText(row.txid);setIscopied(true)}}>
                  <Typography varaint='body2' fontSize={'0.8rem'}>{iscopied ?'copied':'copy'}</Typography>
                </Grid> */}
                <Grid item xs={1} >
                 {iscopied ? <ContentCopy color='primary' sx={{transform:'scale(0.6)'}} onClick={() => { navigator.clipboard.writeText(`${storeUrl}`);setIscopied(true)}}/>: <ContentCopy color='textGrey' sx={{transform:'scale(0.6)'}} onClick={() => { navigator.clipboard.writeText(`${storeUrl}`);setIscopied(true)}}/>}
                 
                </Grid>
                <Grid item xs={11}>
                 {
                  iscopied ? <Typography color={'primary'}fontSize='0.7rem' overflow={true}>{`${storeUrl}`}</Typography> 
                  : <Typography color="textGrey" fontSize='0.7rem' overflow={true}>{`${storeUrl}`}</Typography> 
                 }
                 </Grid>
              </Grid>
            </Grid>
            
          </Grid>  
                       
                            </Grid>
                        </Grid>
                      </Grid>
                       
                    </Grid>

                    <div className="storeShowBottom">
                  
                      {/*   <Typography align='left' color='GrayText'>Contact Details</Typography> */}
                        <div className="storeShowInfo">
                             <PhoneAndroid className="storeShowIcon"/>
                        <span className="storeShowInfoTitle">{phone}</span>
                        </div>
                        <div className="storeShowInfo">
                             <MailOutline className="storeShowIcon"/>
                        <span className="storeShowInfoTitle">{email}</span>
                        </div>
                         {
                          countries!==undefined ? <div className="storeShowInfo">
                          <Money className="storeShowIcon"/>
                     <span className="storeShowInfoTitle">{`${currency
}`}</span>
                     </div>:''
                         }

                        <div className="storeShowInfo">
                             <LocationSearching className="storeShowIcon"/>
                        <span className="storeShowInfoTitle">{`${country.name} , ${state} , ${city}`}</span>
                        </div>

                        {_store.categories.length >0 ? <><Typography  align='left' color='GrayText' >{t("form.store.category.prural")}</Typography>
                        <div className="">
                          <ul>
                           {
                            _store.categories.map((category,index)=>{
                              return(
                                    <li>                                <Typography align='left'>{category.name}</Typography>
                                    </li>
                              )
                            })
                           }</ul>
                          </div></> :''}
                       </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={8} lg={8} >
                  <div className="client-storeUpdate"> 
                   
                    <form  className="client-storeUpdateForm" onSubmit={handleUpdateStore}>
                      <Grid container justifyContent='space-between' >
                       {/*  <Grid item >
                           <Typography align='left'>Update</Typography>

                        </Grid> */}
                            <Grid item xs={12} sm={12} md={6} lg={6} >
                             
                        <div className="storeUpdateItem">
                            <label>{t('form.store.name.label')}</label>
                            <TextField variant='standard' type="text" value={name} disabled onChange={(e)=>{setName(e.target.value)}} className="storeUpdateInput"/>
                        </div>
                        
                       <div className="storeUpdateItem">
                            <label>{t('form.store.email')}</label>
                            <TextField variant='standard' type="text" className="storeUpdateInput" value={email} disabled onChange={(e)=>{setEmail(e.target.value)}}/>
                        </div>
                        <div className="storeUpdateItem">
                            <label>{t('form.store.description.label')}</label>
                            <TextField variant='standard' multiline role='textbox' rows="3" value={description}  onChange={(e)=>{setDescription(e.target.value)}} className="storeUpdateTextarea"/>
                        </div>
                       
                        <div className="storeUpdateItem">
                            <label>{t('form.store.phone')}</label>
                            <TextField variant='standard' type="text" value={phone} onChange={(e)=>{setPhone(e.target.value)}} className="storeUpdateInput"/>
                        </div>
                        <div className="storeUpdateItem">
                            <label>{t('form.store.country')}</label>
                            <TextField variant='standard' type="text" value={country.name} disabled onChange={(e)=>{setCountry(e.target.value)}} className="storeUpdateInput"/>
                        </div>
                        <div className="storeUpdateItem">
                            <label>{t('form.store.state')}</label>
                            <TextField variant='standard' type="text" value={state} onChange={(e)=>{setState(e.target.value)}}className="storeUpdateInput"/>
                        </div>
                        <div className="storeUpdateItem">
                            <label>{t('form.store.city')}</label>
                            <TextField variant='standard' type="text" value={city} onChange={(e)=>{setCity(e.target.value)}} className="storeUpdateInput"/>
                        </div>

                        <div className="storeUpdateSelectItem">
                            <label>{t('form.store.currency')}</label>
                            <Select variant='standard' sx={{textAlign:'left'}} value={currency} name="currency" fullWidth required onChange={onCurrencyChange}>
                   {/*  { rates.map((c,index)=>(
                       <MenuItem key={c.id} value={c.name}>{`${c.name} ${c.country}`} </MenuItem>
                   ))
                    /* countries.map((c,index)=>(
                       <MenuItem key={c.id} value={index}>{`${c.currency} ${c.name}`} </MenuItem>
                   ))  */}
                   </Select> 
                        </div>

                        <div className="storeUpdateItemCheckBox">
                         <Grid container justifyContent='space-between' margintop={5} marginbottom={5}>
                          <Grid item><label>{t('form.store.category.label')}</label></Grid> 
                          {/* <Grid item>
                              <Grid container justifyContent={'space-between'}>
                                {
                                  checkedCategories && checkedCategories.length>0 ? <>
                                  {
                                    checkedCategories.map((cat,index)=>{
                                      return (
                                        <Grid item key={index}>
                                            <Typography variant='h6'>{cat.name}</Typography>
                                        </Grid>
                                      )
                                    })
                                  }
                                  </> :''
                                }
                              </Grid>
                          </Grid> */}
                          <Grid item onClick={()=>{onEditClick()}}><Edit/></Grid>
                         </Grid>
                     {showEditCat ? <CheckboxList data={groupCategories} checkedCategories={checkedCategories} setCheckedCategories={setCheckedCategories} />:''
}
                    {/* <select type="text" name="category" value={categoryid} onChange={(e)=>{handleOnCategoryChange(e)}} required >
                    <option value="none" required></option>
                    {  
                       groupCategories.map((group,index)=>{
                       return <option key={index} value={group._id}>{group.name}</option>
                      })
                      }
               
                    </select> */}
                            </div>  

                      
                 </Grid>

                     <Grid item xs={12} sm={12} md={6} lg={6} marginBottom={5}>
                       <Grid container justifyContent='center'  direction='column' className='updateactionCntainer'>
                           <Grid item border={0}>
                            <input type="file" id="file" onChange={(e)=>{onFileInputChange(e)}} style={{display:"none"}}/>
                            <Button type='submit' variant='contained' color='primary' fullWidth >Update</Button>
                          {/*     <Grid container justifyContent='space-between' direction='column' spacing={2}>
                          <Grid item>
                           <div className="storeUpdateUpload">
                            <img src={`${imageurl!==''? imageurl :storeThumbnail}` }  alt="" className="storeUpdateImg" id="store-image" />
                            <label htmlFor="file"> <Publish className="storeUpdateIcon"/> </label>
                             
                         </div>
                          </Grid>
                          <Grid item>
                          

                          </Grid>
                      </Grid> */}
                           
                              </Grid>
                       </Grid>
                  
                         </Grid>
                      </Grid>
                      
                    </form>
                </div>
                  </Grid>
            </Grid>
           {/*  <div className="storeContainer">
                <div className="storeShow">
                    <div className="storeShowTop">
                        <img src={`${process.env.REACT_APP_SERVER_URL}/uploads/stores/${store.image[0].filename}` } id="store-show-img" alt="" className="storeShowImg" />
                       <div className="storeShowTopTitle">
                           <span className="storeShowstorename">{name}</span>
                           <span className="storeShowstoreTitle">{description}</span>
                       </div>
                       
                    </div>

                    <div className="storeShowBottom">
                  
                        <span className="storeShowTitle">Contact Details</span>
                        <div className="storeShowInfo">
                             <PhoneAndroid className="storeShowIcon"/>
                        <span className="storeShowInfoTitle">{phone}</span>
                        </div>
                        <div className="storeShowInfo">
                             <MailOutline className="storeShowIcon"/>
                        <span className="storeShowInfoTitle">{email}</span>
                        </div>
                        <div className="storeShowInfo">
                             <LocationSearching className="storeShowIcon"/>
                        <span className="storeShowInfoTitle">{`${country} , ${state} , ${city}`}</span>
                        </div>

                        {store.categories.length >0 ? <><span className="storeShowTitle">Categories</span>
                        <div className="">
                          <ul>
                           {
                            store.categories.map((category,index)=>{
                              return(
                                    <li>                                <span className="storeShowInfoTitle">{category.name}</span>
                                    </li>
                              )
                            })
                           }</ul>
                          </div></> :''}
                       </div>
                </div>
                <div className="storeUpdate"> 
                    <span className="storeUpdateTitle" >Edit</span>
                    <form  className="storeUpdateForm" onSubmit={handleUpdate}>
                    <div className="storeUpdateLeft">
                        <div className="storeUpdateItem">
                            <label>StoreName</label>
                            <input type="text" value={name} onChange={(e)=>{setName(e.target.value)}} className="storeUpdateInput"/>
                        </div> 
                        
                        <div className="storeUpdateItem">
                            <label>Email</label>
                            <input type="text" className="storeUpdateInput" value={email} onChange={(e)=>{setEmail(e.target.value)}}/>
                        </div>
                        <div className="storeUpdateItem">
                            <label>Description</label>
                            <textarea rows="3" value={description}  onChange={(e)=>{setDescription(e.target.value)}} className="storeUpdateTextarea"/>
                        </div>
                       
                        <div className="storeUpdateItem">
                            <label>Phone</label>
                            <input type="text" value={phone} onChange={(e)=>{setPhone(e.target.value)}} className="storeUpdateInput"/>
                        </div>
                        <div className="storeUpdateItem">
                            <label>Country</label>
                            <input type="text" value={country} onChange={(e)=>{setCountry(e.target.value)}} className="storeUpdateInput"/>
                        </div>
                        <div className="storeUpdateItem">
                            <label>State</label>
                            <input type="text" value={state} onChange={(e)=>{setState(e.target.value)}}className="storeUpdateInput"/>
                        </div>
                        <div className="storeUpdateItem">
                            <label>City</label>
                            <input type="text" value={city} onChange={(e)=>{setCity(e.target.value)}} className="storeUpdateInput"/>
                        </div>

                        <div className="storeUpdateSelectItem">
                            <label>Currency</label>
                            <Select value={currency} name="currency" autoWidth required onChange={(e)=>{onCurrencyChange(e)}>
                    {countries.map((c,index)=>(
                       <MenuItem key={c.id} value={index}>{`${c.currency} ${c.currency_name}`} </MenuItem>
                   ))}
                   </Select> 
                        </div>

                        <div className="storeUpdateItemCheckBox">
                    <label>Category</label>
                      <CheckboxList data={groupCategories} checkedCategories={checkedCategories} setCheckedCategories={setCheckedCategories} />

                    <select type="text" name="category" value={categoryid} onChange={(e)=>{handleOnCategoryChange(e)}} required >
                    <option value="none" required></option>
                    {  
                       groupCategories.map((group,index)=>{
                       return <option key={index} value={group._id}>{group.name}</option>
                      })
                      }
               
                    </select> 
                </div>  

                        </div> 
                    <div className="storeUpdateRight">
                        <div className="storeUpdateUpload">
                            <img src={`${process.env.REACT_APP_SERVER_URL}/uploads/stores/${store.image[0].filename}`} alt="" className="storeUpdateImg" id="store-image" />
                            <label htmlFor="file"> <Publish className="storeUpdateIcon"/> </label>
                                <input type="file" id="file" onChange={onFileInputChange} style={{display:"none"}}/>
                       </div>
                       <button className="storeUpDateButton">Update</button>
                    </div>
                    </form>
                </div>
            </div> */}
      </div>
  )
}


