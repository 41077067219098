import {makeStyles} from '@material-ui/core/styles'

export default makeStyles((theme)=>({
   
   root:{
       height:'auto',
       overflow:'scroll',
       backgroundColor:'#fff',
      /*  padding:'0px 140px', */
       [theme.breakpoints.down('xs')]:{
/*         margin:'10px 5px',
 */        height:'100vh',
        marginBottom:5,
        
       }
   },
   storedetails:{
    display:'block',
    [theme.breakpoints.down('xs')]:{
        display: 'block',
      },
   },
   editbar:{
    display:'block',
    [theme.breakpoints.down('xs')]:{
        display: 'block',
      },
   },
   userUpdateWrapper:{
    padding: '2px !important',
/*backgroundColor:'white',
 */    [theme.breakpoints.down('xs')]:{
        padding: '20px !important',
        marginBottom:30,
    },
    userInfoIcon:{
      fontSize:'0.7rem'
    },
   storetapIcon:{
    color:'var(--app-primary)',
    transform:'scale(2.5) !important'
   },
   rewardCard:{
    border:'1px solid',
    padding:0,
    backgroundImage:'linear-gradient(to bottom right, var(--app-primary), snow)'

   }
   ,accountPanel:{
    [theme.breakpoints.down('xs')]:{
     display:'none'
  },
   },navBadge:{ "& .MuiBadge-badge": {
    /* fontSize: '0.6rem !important',
    top:'2px !important',
    padding:'7px !important',
     minWidth: '7px !important', */
    transform: 'scale(0.8) translate(30%, 10%)',
  
  } }
}
}))