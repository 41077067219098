import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
    title: {
      marginTop: '4%',

    

    },
    detailsWrapper:{
        border:'1px solid darkgrey',
        borderRadius:2,
        overflow:"hidden",
        [theme.breakpoints.down(620)]:{
          border:0,
         },
    },
   content:{
    backgroundColor:'#fff',
       height:'100vh',
       overflow:'scroll',
       paddingTop:'40px',
       display:'flex',
       flexDirection:'column',
       justifyContent:'space-between',
       alignItems:'start', 
       padding:'3px 5px',
       [theme.breakpoints.down(620)]:{
        paddingTop:'10px !important',
        marginTop:'0px !important',
        marginBottom:'5vh !important',
        paddingLeft:'16px',
        paddingRight:'16px'
       },
       marginBottom:15,
   },
   /* slider:{
    [theme.breakpoints.up(620)]:{
      display:'none',
     },
     [theme.breakpoints.down(620)]:{
      display:'block',
      marginTop:'0px',

     }
   }, */
   imgbox:{
    [theme.breakpoints.up(620)]:{
      display:'none',
     },
     [theme.breakpoints.down(620)]:{
      display:'block',

     }
   },
   commentWrapper:{
    backgroundColor:'rgb(241, 241, 241)',
    maxHeight:'50vh',
    minHeight:'10vh',
    overflow:'scroll',
    marginBottom:'30vh'
   },
   proceedcheckOutcommentList:{
    
   /*   margin:'2% 20%', */
     border:'0px solid',
     width:'100%',
     [theme.breakpoints.down(620)]:{
      /* margin:'2px 5px', */
      width:'100%'
     },
   },
   commentList:{
    display:'flex',
    flexDirection:'column',
    marginTop:20,
    marginBottom:20,
    backgroundColor:'whitesmoke',
    padding:'5px 5px',
    borderRadius:'4px',
    border:'0px solid',
    width:'84%',
    [theme.breakpoints.down(620)]:{
      width:'100%',
      border:'1px solid',

     },

    
  }
   
  
}))