/* eslint-disable no-unused-vars */
import React,{useState,useEffect} from 'react'
import {Paper,Step,Stepper,StepLabel,Divider,Button,CircularProgress,Typography,Grid,Card,CardMedia} from '@mui/material';
//import {MarkunreadOutlined} from '@material-ui/icons'
import {Link} from 'react-router-dom';
import useStyles from'./styles';
import{formarttoPiCurrency} from '../../../utils/Utils'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import AlertDialog  from '../../../components/alertdialog/AlertDialog';
import Comments from '../comments/Comments';
import {axiosClient,config} from '../../../services/axiosClient';
import { styled } from '@mui/material/styles';
import './index.css'
import {BasicRating} from '../../../components';
import { myCld } from '../../../cloudinaryImage'
import AdsTerraNative from '../../ads/AdsterraNative';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import CustomTypography from '../../customComponent/CustomTypography'
import { ContentCopy } from '@mui/icons-material';

const Details = ({order,myOrders,setMyOrders,userid ,user,store,setShowDetails,showDetails,onReviewClick,onStatusUpdate,setMessage,setOpenSnackBar,rateStore,rateProduct,updateAppcredits,setisOrdersSorted,handlerequestRefund,t,translateStatus}) => {
      const [status,setStatus]=useState(order.status.title);
      const [ratedValue,setRatedValue]=useState(0);
      const [productRatedValue,setProductRatedValue]=useState();
      const [isUserRateStoreLoaded,setisUserRateStoreLoaded]=useState(false)
    const [isUserRateProductLoaded,setisUserRateProductLoaded]=useState(false)
    const [isUpdatedOrder,setIsUpdatedOrder]=useState(false)
    const [isuserRateStore,setisuserRateStore]=useState(false)
    const [isuserRateProduct,setisuserRateProduct]=useState(false)
    const url = order.productImageUrl
    const s3url = order.products3ImageUrl
    const array = url.split('/');
    var sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    const[iscopied,setIscopied]=useState(false)

  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  i18n.use(LanguageDetector)
    const lastsegment = array[array.length-1];
    const pid=lastsegment.substring(0, lastsegment.length - 4)
      //isStoreRated(order.storeId,setRatedValue)

    const stepIndicator =(status)=>{
        var step=0;
        switch (status) {
         case 'Paid':
                step=1
                break;
         case 'Shipped':
                step=2
                  break;
         case 'Recieved':
                step=3
                    break;
         case 'Reviewed':
                step=5
                break; 
         case 'Completed':
                step=5
                break;
                           
            default:
                step=1
                break;
        }
        setActiveStep(step)
        return step
    }
    const classes =useStyles();
    //const steps =['Paid','Shipped','Recieved','Reviewed','Completed'];
    const steps =[t("form.product.status.paid"),t("form.product.status.shipped"),t("form.product.status.recieved"),t("form.product.status.reviewed"),t("form.product.status.completed")];

    const [activeStep,setActiveStep]=useState(0);
    const [open,setOpen]=useState(false);
    var userObj =JSON.parse(localStorage.getItem('user'));

    const dialogRef = React.createRef()

    const handleClickOpen = (order) => {
        //setOrderId(order._id);
        let title = order.status.title;
         
        switch (title) {
          case 'Shipped' :
           setStatus('Recieved') 
            break;
           /*  case 'Processing':
             setStatus('Completed') 
              break; */
          default:
           setStatus('Recieved') 
            break;
        }
      title === "Shipped" ? setOpen(true):setOpen(false);
    

   };
   const handleClose = (option) => {
      
    setOpen(false);
     if (option === true) {
        onStatusUpdate(order._id,order.storeId,status)
     }
    //console.log(orderid);
  };
  /*   const nextStep = () =>{ setActiveStep((prevActiveStep) => prevActiveStep + 1)}
    const backStep = () => {setActiveStep((prevActiveStep) => prevActiveStep - 1)}
     */
    const FreeTag= ()=>(
        <Grid container justifyContent={'flex-start'} spacing={1}>
          <Grid item ><Typography variant="body2" >
      {t("form.product.shipping.label")}:
  </Typography></Grid>
          <Grid item ><Typography variant="body2"   className={classes.freeTag}>
      free
  </Typography></Grid>
        </Grid>
     )
    let Confirmation = () => (order.customer ? (
        <>
          <div>
            <Typography variant="h5">Thank you for your purchase, {order.customer.firstname} {order.customer.lastname}!</Typography>
            <Divider className={classes.divider} />
            <CustomTypography variant="subtitle2">Order ref: {order.orderNumber}</CustomTypography>
            {order.url !=='' ?
            
            <Grid container>
             
               <Grid item xs={8} >
                 {iscopied ? <ContentCopy color='primary' sx={{transform:'scale(0.6)'}} onClick={() => { navigator.clipboard.writeText(`${order.url}`);setIscopied(true)}}/>: <ContentCopy color='textGrey' sx={{transform:'scale(0.6)'}} onClick={() => { navigator.clipboard.writeText(`${order.url}`);setIscopied(true)}}/>}
                 
                </Grid>
                {
                  iscopied ? <CustomTypography color={'primary'}fontSize='0.7rem' overflow={true}>{`${order.url}`}</CustomTypography> 
                  : <CustomTypography color="textGrey" fontSize='0.7rem' overflow={true}>{`${order.url}`}</CustomTypography> 
                 }
            </Grid>:''}
          </div>
          <br />
          <Button component={Link} variant="outlined" type="button" to="/">Back to home</Button>
        </>
      ) : (
        <div className={classes.spinner}>
          <CircularProgress />
        </div>
      ));
     /*  const Reciept = () => activeStep === 0 ?<>paid</> : activeStep === 1 ? <>Recived</> : activeStep === 2 ? <>Reviewed</> : activeStep === 3 ? <>Evaluated</> : activeStep === 4 ? <>Completed</>
      :<></>   */
      function returnClass(status){
        
      }
      const Reciept = () =>(
         <div style={{padding:"5px 10px"}} >
                  
                      <Typography variant='body2' align='left' style={{border:'0px solid darkgray ',color:'darkgray',padding:'2px 5px'}}>{`${order.customer.firstname} ${order.customer.lastname},${order.shippingData.country},${order.shippingData.county_state},${order.shippingData.town_city},${order.shippingData.home_address}`}</Typography> 
                       
                    
                     <div style={{border:'0px solid',padding:'0',margin:'5px 0px'}}>
                    {/*  <Typography variant='body1' style={{padding:'2px 5px'}}>{store.name}</Typography> */}
                     <Grid container justifyContent={'space-between'}>
                        <Grid item xs={3} sm={3} md={3} lg={3}>
                      
                        <img className={classes.media} alt='product-img' src={process.env.REACT_APP_WEB_IMAGE_LOCATION === 's3' ? s3url :myCld.image(`products/${pid}`).format('auto').quality('auto').addFlag('lossy').toURL()} />
                        
                        </Grid>

                        <Grid item xs={9} sm={9} md={9} lg={9} padding={1}  >
                        <Grid container justifyContent={'space-between'} direction={'column'} alignItems={'flex-start'}>
                            
                            
                                <Grid item>
                                 <Typography variant='h6'color={'GrayText'} fontSize={'0.80rem'}>{order.name}</Typography>
                                </Grid>
                                <Grid item >
                                  <Grid container>
                                    <Grid item >
                            <Typography variant='body2' className={classes.price}>
                                 {`${formarttoPiCurrency(order.priceEach,'π')}`}
                                 </Typography>
                                    </Grid>
                                   {/*  <Grid item   marginLeft={8}>
                            {order.status.title !== "Completed" ?  <Grid item xs={12} alignContent={'flex-end'}>
                            <Button className='client-action-Button' variant='outlined' color='Completed' onClick={()=>{handlerequestRefund(order)}}>{!order.requestRefund ? 'Cancel Refund':'Request Refund'}</Button>
                          </Grid>:''}
                            </Grid>  */}
                                  </Grid>
                                 
                                </Grid>
                         
 
                           
                          </Grid>
                         </Grid>
                        </Grid>
                 <Grid container justifyContent={'space-between'}>
                            <Grid item xs={12}>
                                  <Grid  container justifyContent={'space-between'} >
                                    <Grid item xs={4} >
                                    <Typography  align='left' color={'GrayText'}   variant="body2" >
                       {t("form.product.quantity.label")}
                      </Typography>
                                    </Grid>

                                    <Grid item xs={8} >
                                    <Typography align='left' color={'GrayText'} variant="body2">
                        {order.quantity}
                      </Typography>
                                    </Grid>
                                  </Grid>
                            </Grid>

                            <Grid item  xs={12}>
                                  <Grid  container justifyContent={'space-between'}>
                                    <Grid item xs={4}>
                                    <Typography align='left' color={'GrayText'}variant="body2" > {t("checkout.total")}   
                      </Typography>
                                    </Grid>

                                    <Grid item xs={8}>
                                    <Typography align='left' color={'GrayText'}variant="body2" className={classes.price}>
                       {`${formarttoPiCurrency(order.totalPrice+order.shippingFees,'π')}`}  
                      </Typography>
                                    </Grid>
                                  </Grid>
                            </Grid>

                            <Grid item  xs={12}>
                                  <Grid  container justifyContent={'space-between'}>
                                    <Grid item xs={4}>
                                    <Typography align='left' color={'GrayText'} variant="body2"> {t("order.date")} </Typography>
                                    </Grid>

                                    <Grid item xs={8}>
                                    <Typography align='left' color={'GrayText'} variant="body2">
                                     {`${new Date(order.dateCreated).toUTCString()}`} </Typography>
                                    </Grid>
                                  </Grid>
                            </Grid>
                            <Grid item  xs={12}>
                                  <Grid  container justifyContent={'space-between'}>
                                    <Grid item xs={4}>
                                    {order.color!=='null' ?      <Typography variant="body2" align='left' color={'GrayText'} className={classes.color}>
                        {order.color ? `Color `:''}  
                      </Typography>:''}
                                    </Grid>

                                    <Grid item xs={8}>
                                    {order.color!=='null' ?      <Typography variant="body2" align='left' color={'GrayText'} className={classes.color}>
                        {order.color ? ` ${order.color}`:''}  
                      </Typography>:''}
                                    </Grid>
                                  </Grid>
                            </Grid>

                            <Grid item  xs={12}>
                                  <Grid  container justifyContent={'space-between'}>
                                    <Grid item xs={4}>
                                    {order.size!=='null' ?      <Typography variant="body2" align='left' color={'GrayText'} className={classes.size}>
                        {order.size ? `Size `:''}  
                      </Typography>:''}
                                    </Grid>

                                    <Grid item xs={8}>
                                    {order.size!=='null' ?      <Typography variant="body2" align='left' color={'GrayText'} className={classes.size}>
                        {order.size ? ` ${order.size}`:''}  
                      </Typography>:''}
                                    </Grid>
                                  </Grid>
                            </Grid>

                            <Grid item  xs={12}>
                                  <Grid  container justifyContent={'space-between'}>
                                    <Grid item xs={4}>
                                    <Typography align='left' color={'GrayText'} variant="body2" >
                        {order.orderNumber ? `${t("order.ordernumber")}` :''}  
                      </Typography>

                                    </Grid>

                                    <Grid item xs={8}>
                                    <Typography align='left' color={'GrayText'} variant="body2" >
                        {order.orderNumber ? ` #${order.orderNumber}` :''}  
                      </Typography>

                                    </Grid>

                                     {order.url  && order.txid?
            
            <Grid container justifyContent='flex-end'>
             
               <Grid item xs={2} >
                 {iscopied ? <ContentCopy color='primary' sx={{transform:'scale(0.6)'}} onClick={() => { navigator.clipboard.writeText(`${order.url}`);setIscopied(true)}}/>: <ContentCopy color='textGrey' sx={{transform:'scale(0.6)'}} onClick={() => { navigator.clipboard.writeText(`${order.url}`);setIscopied(true)}}/>}
                 
                </Grid>
               <Grid item xs={10}>
               {
                  iscopied ? <CustomTypography color={'primary'}fontSize='0.7rem' overflow={true}>{`${order.url ? order.url:''}`}</CustomTypography> 
                  : <CustomTypography color="textGrey" fontSize='0.7rem' overflow={true}>{`${order.url?order.url:''}`}</CustomTypography> 
                 }
                </Grid>
            </Grid>:''}
                                  </Grid>
                            </Grid>
                      </Grid>
                     <div className={classes.contentSubdetailsWrapper}>
                      
                   
                 <Grid container justifyContent={'space-between'} direction='column' marginTop={2}>
                      
                      <Grid item>
                         <Typography  variant="body2" onClick={()=>{handleClickOpen(order)}} className={order.status.title}>
                        {order.status.title === 'Shipped' ? `${t("order.confirmrecieved")}`:translateStatus(order.status.title)  
}
                      </Typography>

                      </Grid>
                     
                 </Grid>
                   </div>
                   </div>   
                 
            </div>
        
      )
      

    const QontoConnector = styled(StepConnector)(({ theme }) => ({
        [`&.${stepConnectorClasses.alternativeLabel}`]: {
          top: 10,
          left: 'calc(-50% + 16px)',
          right: 'calc(50% + 16px)',
        },
        [`&.${stepConnectorClasses.active}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.secondary,
          },
        },
        [`&.${stepConnectorClasses.completed}`]: {
          [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.secondary,
          },
        },
        [`& .${stepConnectorClasses.line}`]: {
          borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
          borderTopWidth: 3,
          borderRadius: 1,
        },
      }));

      React.useEffect(()=>{
        setStatus(order.status.title)
        stepIndicator(order.status.title)
        
        const fetchOrder = async () =>{
            getOrder(order._id).then((response)=>{

                  if (response.data.status===200){
                    const order=response.data.order
                    //console.log(order)
                    myOrders.map(o => o._id !==order._id ? o :order)
                    setMyOrders(myOrders.map(o => o._id !==order._id ? o :order))
                  }
            })
        }
        const getOrder = async (orderid) => {
          const url=`${process.env.REACT_APP_SERVER_URL}/orders/byid/${orderid}`

           return await axiosClient.get(url,{},config);
        }
        const isStoreRated = async ()=>{
          const url = `${process.env.REACT_APP_SERVER_URL}/storerating/checkisuserrated`;
            
          return await  axiosClient.post(url,{userId:userid,storeId:order.storeId},config);
        }

        const isProductRated = async ()=>{
          const url = `${process.env.REACT_APP_SERVER_URL}/productrating/checkisuserrated`;
            
          return await  axiosClient.post(url,{productId:order.productId,storeId:order.storeId,userId:user._id},config);
        }
        
        const getIsProductRated = async ()=>{
  
          await isProductRated().then((response)=>{
           try{
               if (response.data.status === 200){
                console.log(response.data.rating[0].rate)
                setProductRatedValue(response.data.rating[0].rate)
              /* setMessage({body:`${response.data.message}`,severity:'info'})
              setOpenSnackBar(true) */
          
             
             }
           }catch(err){
 
           }
             
          })
         }

        const getIsStoreRated = async ()=>{
  
         await isStoreRated().then((response)=>{
          try{
              if (response.data.status === 200){
              // console.log(response.data.rating[0].rate)
               setRatedValue (response.data.rating[0].rate)
               setisuserRateStore(true)
             /* setMessage({body:`${response.data.message}`,severity:'info'})
             setOpenSnackBar(true) */
         
            
            }else{
              setisuserRateStore(false)
             setRatedValue(response.data.rating[0].rate)
         
            }
          }catch(err){

          }
            
         })
        }
       /*  if (!isUserRateStoreLoaded){
          getIsStoreRated()
        } */
        if(!isUserRateProductLoaded){
          getIsProductRated()
        }
        if (!isUpdatedOrder){
          fetchOrder(order._id)
        }

        return()=>{
         //setisUserRateStoreLoaded(true)
          setIsUpdatedOrder(true)
          setisUserRateProductLoaded(true)
        }

      }, [isUpdatedOrder, isUserRateProductLoaded, isUserRateStoreLoaded, myOrders, order, productRatedValue, setMyOrders, user._id, userid])
      
  return (
    <div>
      <main className={classes.layout}>
        <AlertDialog dialogRef={dialogRef}  open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} title={t("dialog.Recievedconfirmation.title")} textContent={t("dialog.Recievedconfirmation.message",{status:translateStatus(status)})}  positiveButtonText={t("dialog.button.yes")}negativeButtonText={t("dialog.button.cancel")}/>
        <Grid container justifyContent={'space-between'} margin={'0px 8px'}>
            <Grid item > <Typography variant="h5">{t("progress")} </Typography></Grid>
            <Grid item > <Button  size='small' color='primary' variant='text' onClick={()=>{setShowDetails(!showDetails)/* ;setisOrdersSorted(false) */}}>{t("done")}</Button></Grid>
  
     
        </Grid>
          <div className={classes.paper}>
      {/*   <Typography variant="h6" align="center">Progress</Typography> */}
        <Stepper activeStep={activeStep} className={classes.stepper} connector={<QontoConnector />}>
            {steps.map((step)=>(
                <Step className={classes.step}  key={step}>
                     <StepLabel classes={{ label: classes.step_label_root ,iconContainer:classes.iconContainer}} fontSize={8}>{step}</StepLabel>
                </Step>

            ))}       
        </Stepper>
        <Reciept/>
       {
        /* !isuserRateStore ?  <>{order.status.title ==='Recieved'? <BasicRating title={`${ratedValue>0? 'Store Rate ':'Rate Store ' }`} ratedValue={ratedValue} setRatedValue={setRatedValue} /> :''}</>:'' */
       }

     <Grid container justifyContent={'flex-start'} paddingLeft={2}>
       {productRatedValue ? <Grid item >
       {order.status.title ==='Recieved'? <BasicRating title={`${productRatedValue >0? 'Product Rate ':'Rate Product ' }`} ratedValue={productRatedValue} setRatedValue={setProductRatedValue} /> :''} 
      {/*  {
        !isuserRateProduct ?  <></>:''
       } */}
        </Grid>:''}
     </Grid>
        
        
        {activeStep >=2 ? <div style={{height:'35vh',overflow:'scroll',marginBottom:'10vh'}}>{order.status.title==='Recieved' ? <Comments  userid={userid} user={user} order={order} ratedValue={productRatedValue} setMessage={setMessage} setOpenSnackBar={setOpenSnackBar} rateStore={rateStore} rateProduct={rateProduct} updateAppcredits={updateAppcredits} />:''}</div> :''}
        {/* {activeStep===steps.length? <Confirmation/>:order&& <Reciept/>} */}
        </div>

      </main>
    </div>
  )
}

export default Details
