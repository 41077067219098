import React,{useState} from 'react'
import {Grid,Paper,Stepper,Step,StepLabel,Typography,CircularProgress,Divider,Button} from '@mui/material';
import { Link } from 'react-router-dom';

import useStyles from './styles';
import AddressForm from '../AddressForm';
import PaymentForm from '../PaymentForm';
import Medialinks from '../../medialinks/Medialinks'

const CheckOut = ({cart,user,order,onCaptureCheckout,error,addresses,setAddresses,handleDeleteAddress,showAddresses,setShowAddresses,setAddaddress,checkInCompletePayments,medialinks,showAds,t, handleFocus,handleBlur}) => {
    const [activeStep,setActiveStep]=useState(0);
    const [ischeckAddress,setCheckadress]= useState(false);
    const [shippingData,setShippingData]=useState({});
    const [address,setAddress]= useState(addresses.length>0 ? addresses[0]:{});
    const [selectedValue, setSelectedValue] = React.useState('a');
    const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState('a');
    const[paymentMethod,setPaymentMethod]=useState('');
    const[iscompletePaymentCheck,setisIncompletePaymentCheck]=useState(false);
    const steps =[`${t("checkout.shippingaddress")}`,`${t("checkout.paymentdetails")}`];

    /* const updateShowAddress = () => setShowAddresses( addresses.length > 0 ?true :false)
 */
    const classes = useStyles();
  //  const history = useHistory();
    const nextStep = () =>{ setActiveStep((prevActiveStep) => prevActiveStep + 1)}
    const backStep = () => {setActiveStep((prevActiveStep) => prevActiveStep - 1)}


    const handleonRadioButtonChanged = (event,alphabets) => {
      var value=event.target.value  
      
      var index=alphabets.indexOf(value);
      var addr=addresses[index];
      setSelectedValue(event.target.value);
      setAddress(addr)
             console.log(addr);

    };

    const handleonPaymentMethodChanged = (event,alphabets) => {
      var value=event.target.value  
      
     /*  var index=alphabets.indexOf(value);
      var addr=addresses[index]; */
      setSelectedPaymentMethod(event.target.value);
     /*  setAddress(addr) */
             console.log(value);
            setPaymentMethod(value)
    };
    
    

     const next = (data) =>{
            setShippingData(data);
            nextStep();
     };
  let Confirmation = () => (order.customer ? (
    <>
      <div className={classes.confirmation}>
        <Typography variant="body1"> {` ${t("confirmation.thankyoumessage")} ${order.customer.firstname}` } {/* {order.customer.lastname} */} { `):`}</Typography>
       
        <Typography variant="subtitle2"> {`${t("confirmation.orderreference")} ${order.shipping.orderNumber}`}</Typography> 
         <Divider className={classes.divider} />
        <Typography variant="body1" style={{fontSize:'0.8rem',padding:'4px',margin:'1px',border:'1px solid var( --app-font-darkgray)',color:'var(--app-font-darkgray)',}}>{t("notice.orderguide")}</Typography>
      </div>
      <br />
      <>
             <Typography variant='body2' className='recents-products-title' >{t("notice.fellowsocialmedia")}</Typography>
          {  medialinks ?  <Medialinks medialinks={medialinks} />:''}
             </>
      <Grid container justifyContent='space-between'>
            <Grid item > <Button sx={{textTransform:'none'}} className='client-action-Buttonn' component={Link} variant="outlined" type="button" to="/">{t("checkout.backtohomebtn")}</Button>
            </Grid>
            <Grid item > <Button sx={{textTransform:'none'}} className='client-action-Buttonn' component={Link} variant="outlined" type="button" to="/cart">{t("checkout.title")}</Button>
            </Grid>
        </Grid>
    </>
  ) : (
    <div className={classes.spinner}>
      <CircularProgress />
    </div>
  ));

  if (error) {
    Confirmation = () => (
      <>
        <Typography variant="h5">Error: {t("checkout.resposne.error")}</Typography>
        <br />
        <Grid container justifyContent='space-between'>
            <Grid item > <Button sx={{textTransform:'none'}} className='client-action-Buttonn' component={Link} variant="outlined" type="button" to="/">{t("checkout.backtocart")}</Button>
            </Grid>
            <Grid item > <Button sx={{textTransform:'none'}} className='client-action-Buttonn' component={Link} variant="outlined" type="button" to="/cart">{t("checkout.title")}</Button>
            </Grid>
        </Grid>
      </>
    );
  }
     const Form = () => activeStep === 0
     ?<AddressForm cart={cart}  next={next} address={address}  setAddress={setAddress} addresses={addresses} showAddresses={showAddresses} setShowAddresses={setShowAddresses} handleonRadioButtonChanged={handleonRadioButtonChanged} selectedValue={selectedValue} handleDeleteAddress={handleDeleteAddress} setAddaddress={setAddaddress} showAds={showAds} t={t}  handleFocus={handleFocus}
     handleBlur={handleBlur}/>
     :<PaymentForm shippingData={shippingData}   cart={cart} nextStep={nextStep} backStep={backStep}  onCaptureCheckout={onCaptureCheckout}  showAddresses={showAddresses} setShowAddresses={setShowAddresses} selectedPaymentMethod={selectedPaymentMethod} handleonPaymentMethodChanged={handleonPaymentMethodChanged} paymentMethod={paymentMethod} setActiveStep={setActiveStep} t={t}  handleFocus={handleFocus}
     handleBlur={handleBlur}/>
    React.useEffect(()=>{
        if(!ischeckAddress){
          const user =  JSON.parse(localStorage.getItem('user')).user
            
          if (user.addresses.length >0){
           setShowAddresses(true)
           setAddresses(user.addresses)
           
          }
        }
       if (!iscompletePaymentCheck){
        checkInCompletePayments()
       }
        return ()=>{
          setCheckadress(true)
          setisIncompletePaymentCheck(true)
        }
    })
     
  return (
     <div className={classes.rot}>
{/*      <div className={classes.toolbar}/>
 */}      <main  className={classes.layout}>
          <Paper className={classes.paper} sx={{boxShadow:0,backgroundColor:'white'}} >
        <Typography variant="h4" align="center">{t("checkout.title")}</Typography>
        <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((step)=>(
                <Step key={step}>
                     <StepLabel>{step}</StepLabel>
                </Step>

            ))}
            
        </Stepper>
        {activeStep===steps.length? <Confirmation/>:cart&&<Form/>}
        </Paper>
      </main>
      
     </div>
  )
}

export default CheckOut
