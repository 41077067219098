import * as React from 'react';
import Box from '@mui/material/Box';
import {Button,Grid}  from '@mui/material';
import Typography     from '@mui/material/Typography';
import Modal          from '@mui/material/Modal';
import {ArrowDownward} from '@material-ui/icons'
import {useHistory
} from "react-router-dom";
const style = {
  position: 'absolute',
  top: '35%',
  left: '10%', 
  /* transform: 'translate(-50%, -50%)',*/
 
  bgcolor: 'snow',
  color:'#000',
  border: '0px solid var(--app-primary)',
/*   borderTopRightRadius: 12, 
 */  borderRadius: 2, 
  boxShadow: 24,
  p: 2,
   // Responsive dimensions  
   width: {  
    xs: "80%",  // For extra-small screens  
    sm: "80%",  // For small screens  
    md: "70%",  // For medium screens  
    lg: "60%",  // For large screens  
  },  
  height: {  
    xs: "18vh", // For extra-small screens  
    sm: "18vh", // For small screens  
    md: "35vh", // For medium screens  
    lg: "35vh", // For large screens  
  },  
};

export default function UpdateBowserModal({openBrowsermodal,setOpenBrowsermodal,handleOpen}) {  
   const actionref = React.useRef(null)
   const history = useHistory()
  const handleNavigate = () => {
     history.push('https://play.google.com/store/apps/details?id=pi.browser&pcampaignid=web_share')
   setOpenBrowsermodal(false)

  };

  return (
    <div>
      <Modal 
        open={openBrowsermodal}
       /*  onClose={handleClose} */
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         <Grid container justifyContent='space-between'>
          <Grid item>
             <Typography id="modal-modal-title"  variant="h6" >
           Pi Browser is Outdated 
          </Typography></Grid>
         {/*  <Grid item onClick={()=>handleClose()}>X</Grid> */}
         </Grid>

         <Grid item xs={12}>
         <Grid container justifyContent='center' alignItems='center' paddingTop={4}>
          <Grid item>
            <Button color='primary' sx={{textTransform:'none'}}  onClick={()=>{handleNavigate()}}>Update</Button>
            </Grid>
         </Grid>
        </Grid>
         
         
        </Box>
      </Modal>
    </div>
  );
}