import { Grid,Card,CardMedia} from '@mui/material'
import React,{useState,useEffect} from 'react'
import styles from './styles'
import { styled } from '@mui/system';
import { myCld } from '../../../cloudinaryImage';

const StyledCircularCard = styled(Card)({
  height: 120,
  width: 120,
  borderRadius: '50%',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  
});

const ImagedCategories = ({groupCategories,handlesearchByCategoryGroups}) => {
  const classes =styles()

   const ImageCard =({category,handlesearchByCategoryGroups})=>{
     const [image,setImage]=useState()
     const [isSet,setIset]=useState(false)
      useEffect(()=>{
          try {
           if (!isSet){
            setImage(category.img? myCld.image(category.img.public_id).format('auto').quality('auto').addFlag('lossy') :'')
           }
          } catch (error) {
            console.log(error)
          }
        return () =>{
            setIset(true)
        }
      },[category.img, isSet])
    return(
     <StyledCircularCard onClick={()=>{handlesearchByCategoryGroups(category._id)}} > 
        <CardMedia component={'img'} alt={category.name} sx={{ height: '100%', width: '100%', objectFit: 'cover',cursor:'pointer' }}  className={classes.catImage} /* style={{backgroundColor: "transparent"}} */ image={image}/> 
      </StyledCircularCard>
    )
   }
  return (
     <Grid container justifyContent={'center'} alignItems={'center'} spacing={0} border={0}>
        <Grid item xs={12}>
        <Grid container  justifyContent={'space-between'}  padding={2}>
               {

        groupCategories.map((category,i)=>{
                  return(
                    <Grid item key={`category-${i}`} xs={2} marginBottom={2}>
                    {/*   <img url={`${cat.icon.secure_url}`} alt='category img' style={{backgroundColor: "transparent",height:'50px',width:'50px'}} className={classes.catIcon} /> */}
                       <ImageCard category={category} handlesearchByCategoryGroups={handlesearchByCategoryGroups}/>
                      </Grid>
                  )
                })
               }
       
                
               
            </Grid>
        </Grid>
    </Grid>
  )
}

export default ImagedCategories
