/* eslint-disable no-unused-vars */
import { React, useState,useEffect,useCallback} from 'react';
import './new-Product.css';
import Specs from './specs/Specs'
import { Box,Grid,Button,Typography,Select,MenuItem,useMediaQuery, FormControl, InputLabel} from '@mui/material';
import {ExpandMore,ExpandLess} from '@mui/icons-material'
import thumbnail from './ImagesContainer/thumbnail-wide.png';
import {useHistory} from 'react-router-dom';
import ImageGallery from './imageGallery/ImageGallery';
// eslint-disable-next-line no-unused-vars
import { EditorState , convertToRaw} from 'draft-js';
import CheckboxList from '../../newStore/checkList/CheckboxList';
import draftToHtml from 'draftjs-to-html';
import {StoreCard} from '../../../components';
import {Countries} from '../../../assets/jsonData/countries';
import ContentTitleBar from '../../../components/contentitlebar/ContentTitleBar';
import TextEditor from './textEditor/TextEditor';
import useStyles from './style'
import {axiosClient,config} from '../../../services/axiosClient'
import CategoryIcon from '../../category/categoryIcon/CategoryIcon';
import { truncateStringNoSuffix } from '../../../utils/Utils';
import Storecad from '../../storecard/Storedcad';
import CustomTextField from '../../customComponent/CustomTextField';
import CustomTypography from '../../customComponent/CustomTypography'

export default function NewProduct({store,balance,productCategories,onSubmitProduct,setMessage,setOpenSnackBar,accessToken,handlegetTransactions,page,perPage,setPageper,t,theme}) {
  const getCountry= (data) =>{
    let country = {}
   if(data.length >0){
    for(var i=0;i<data.length;i++){
      const obj ={name: data[i].name, countryCode: data[i].iso2}
      
      if (JSON.stringify(obj)===JSON.stringify(store.country)){
           country=data[i] //assign whole country object

      }
    }
  } 
    return country
  
}
    const [productImages,setProductImages]=useState([]);
    const [base64EncodedImage,/* setBase64EncodedImage */]=useState([]);
    const [digitalProductUrl, setDigitalProductUrl] = useState('');

    const [name, setName] = useState('');
    const [description, setDescription] = useState();
    const [descriptionLength, setDescriptionLength] = useState(0);  

    const [shippingarea,setShippingarea]=useState()
    const [price, setPrice] = useState('');
    const [shippingFees,setShippingFees] =useState();
    const [stock,setStock]=useState();
    const [active,setActive]=useState('');
    const [shippingDuration,setShippingDuration]=useState('');
    const [deliveryTime, setDeliveryTime] = useState("");
    const [timeUnit, setTimeUnit] = useState("days");

    const [lifespan,setLifeSpan]=useState('');
    const [specification,setSpecification] = useState('none');
    const [productCategory,setProductCategory]=useState({});
    const [selectedCategory,setSelectedCategory]=useState()
    const [showSpecification,setShowSpeicification]=useState(true);
    const [showDigitalProductFileInput,setShowDigitalProductFileInput] = useState(false);
    const [checkedCategories,setCheckedCategories] =useState([getCountry(Countries)])
    const[isCountriesloaded,setIscountriesLoaded]=useState(false)
    const [countries,setCountries]=useState([]);
    const [clearImages,setClearImages]=useState(false);
    const [showChecklist,setShowCheckList]=useState(false)
    //retrieves specs variables eg colors  ans size
    const [colors,setColors]=useState([]);
    const [sizes,setSizes]=useState([]);
    const [editorstate,setEditorState]=useState(EditorState.createEmpty());
    //const[category]=useState(store.category);
    const[categories]=useState(store.categories.length > 0 ? store.categories :[]);
    const[filStoreProtCategories,setFilStoreProCategories]=useState([]);
    const[isfiltered,setIsfiltered]=useState(false)
    const[countryScope,setCountryScope]=useState([store.country])
    const[isTransactionsLoaded,setIsTransactionsLoaded]=useState(false)
    const isDesktop = useMediaQuery('(min-width:600px)');
    const[resetSpecs,setResetSpecs]=useState(false)
     const[mcolors,setmColors] = useState([""]); //initiliaze color inputs 
    const[msizes,setmSizes]   = useState([""]); //initilaize size inputs
         // let query=QueryParams();

         const  history = useHistory()

         const filterProduct =useCallback(()=>{
          var arr=[];

           for (var i=0;i < categories.length;i++){
            for(var j=0;j < productCategories.length;j++ ){

                  if (categories[i]._id===productCategories[j].categoryGroupId){
                    console.log(productCategories[j].categoryGroupId)
                     
                    arr.push(productCategories[j])
                  }
              }
           }
           setFilStoreProCategories(arr)
           //console.log(arr)
         },[categories, productCategories])

        const onSpecificationChange = (e) => {
        setSpecification(e.target.value)
        if(e.target.value==="no"){
          setShowSpeicification(false);
        }else{
          setShowSpeicification(true)
          setShowDigitalProductFileInput(false) //Specification is set SPECIFIED ,hide Digital product file input
        }
      }
      const onProductCategoryChange = (e) => {
        const val =e.target.value
        setSelectedCategory(val)
        const prodcat=productCategories.filter((item)=>item._id===val)
        setProductCategory(prodcat[0])
        console.log(prodcat)
      }
      const onstockChange =(e)=>{
          setStock(e.target.value)
      }
      const onshippingFeesChange =(e)=>{
        setShippingFees(e.target.value !== '' ?e.target.value : 0)  
      }
      const onAddProductCLick =()=>{
        //  let storeid=document.getElementById("storeselect").value
         // console.log(storeid)
          //setStoreId(storeid)
        
      }

      const getCountryNames = (data) =>{
          let countries = [store.country] //iniatia a default country 
         if(data.length >0){
          for(var i=0;i<data.length;i++){ //add countries which are not included already
              const obj ={name: data[i].name, countryCode: data[i].iso2}
              
            
          
            const found = countries.some(item => item.name !== obj.name); // check if country not found
               console.log(found)
            if(found){
              countries.push(obj)

            }

          }
         
         // console.log(countries)

          setCountryScope((countryscope) => [...countryscope,...countries]);

         // console.log(countryScope)
         return countries

         }else{
           // setCountryScope(countryScope.slice(0,1))
            // console.log(countryScope)
           return countries
         }
      }

     const handleOnFormsubmit =(e,clearFields,colors,sizes,name,price,shippingFees,categoryId,description,specification,digitalProductUrl,storeid,storetag,stock,active,base64EncodedImage,productCategory)=>{
      const form = e.currentTarget
      if (form.checkValidity() === false) {
        e.stopPropagation()
      }
      
      
      e.preventDefault()// Stop form default submit
        
      let countryscope = getCountryNames(checkedCategories); //get country names from selected countries
      console.log(countryscope)
     if (!showDigitalProductFileInput){

      if (!deliveryTime || deliveryTime <= 0) {
        setMessage({body:'Please enter a valid delivery time.',severity:'error'})
        setOpenSnackBar(true)
        return;
    }
    }
     if(!selectedCategory){
      setMessage({body:'Please select your prodcut category .',severity:'error'})
      setOpenSnackBar(true)
      return;
     }

      var body={
        name:name,
        price:price,
        shippingFees:shippingFees,
        shippingDuration: { value: parseInt(deliveryTime), unit: timeUnit },
        description:description,
        specification:specification,
        digital_product_url:digitalProductUrl,
        storeId:storeid,
        storeTag:storetag,
        stock:stock,
        active:active,
        lifespan:lifespan,
        color:colors,
        size:sizes,
        encodedimages:base64EncodedImage,
        category:productCategory,
        countryScope:countryscope,
        shippingArea:shippingarea
       
      }
     
     // var auth_token=userObj.auth_token

       if (productImages.length >= 3){
          onSubmitProduct(e,clearFields,body,accessToken);

       }else{
        setMessage({body:'add three images of product',severity:'error'})
        setOpenSnackBar(true)
       }
     }
      const clearImagesonSubmit=(images)=>{
         if (clearImages) {
           images=[];
         }
      }
      

      const clearFields = () =>{
      
        setName('')
        setDescription('')
        setStock(0)
        setShippingDuration(0)
        setActive('no')
        setShippingarea('')
        setPrice('0')
        setShippingFees('0')
        setSelectedCategory("")
        setProductCategory("")
        setColors(() =>[])
        setSizes(() =>[])
        setResetSpecs(true)
        setmColors([""]);
        setmSizes([""]);
        setDigitalProductUrl("")
       // setEditorState(EditorState.createEmpty());
        document.getElementById("product-image0").src=thumbnail;
        document.getElementById("product-image1").src=thumbnail;
        document.getElementById("product-image2").src=thumbnail;
        
      // setShowSpeicification(false);
       setShowDigitalProductFileInput(false);
       setProductImages([]);
       setCountryScope([]);
       setCheckedCategories([])
       setClearImages(true);

     
      }
    
    
      const onDigitalProductUrlChange = (e) => {
        setDigitalProductUrl(e.target.value);
      }
      const handleNavigate =(location)=>{
        history.push(location)
    } 
      const onDigitalProuctInputChange = (e)=>{
        setShowDigitalProductFileInput(!showDigitalProductFileInput)
           /*  if(e.target.value==="no"){
             setShowDigitalProductFileInput(false)
            }else{
             setShowDigitalProductFileInput(true)
            } */
            console.log(e.target.value)
      }
        
      const handleImages=(Images)=>{
        let tmp=[];
       Images.map((item)=>{
          tmp.push(item)
         // console.log(tmp);
          return null
       })
       //setProductImages(tmp);
    }
   
    const onEditorStateChange = (editorstate)=>{
      console.log(editorstate.getCurrentContent())
      const htmlcontent=draftToHtml(convertToRaw(editorstate.getCurrentContent()))
     // setEditorState(editorstate)
      setDescription(htmlcontent);
      
  }
 
    
    
    
        //Get color and size input Values from input els, @elId is element Id 
      const getInputValues = (elId) => {
        var inputValues = [];
    
        var inputEl = document.getElementsByClassName(elId);
    
        for (var i = 0; i < inputEl.length; i++) {
             inputValues.push(inputEl[i].value);
        }
         //console.log(inputValues)
        return inputValues;
      }

     
     useEffect(()=>{
      //
      if (!isfiltered){
        filterProduct();
      }
       if (!isTransactionsLoaded){
        handlegetTransactions(page,perPage)
       }

      return ()=>{
       if (filStoreProtCategories.length>0){
        setIsfiltered(true)
       }
       setIsTransactionsLoaded(true)
      }
     },[filStoreProtCategories.length, filterProduct, handlegetTransactions, isTransactionsLoaded, isfiltered, page, perPage])

     const getCountries =  useCallback( async() => {

      // if (!mountedRef.current) return null ;

     if (!isCountriesloaded){
      try{
        const url=`${process.env.REACT_APP_SERVER_URL}/countries`;
        await axiosClient.get(url,config).then((response)=>
              setCountries(response.data.countries)
             
        )
        // console.log(mountedRef.current)
        
        }catch(err){
            console.log(err)
        }
     }
    },[isCountriesloaded]);
  
    useEffect(()=> {
   //  addresses.length >0 ? setShowAddresses(true):setShowAddresses(false);
     if (!isCountriesloaded) {getCountries();}
      return ()=>{
        setIscountriesLoaded(true)
       // mountedRef.current=false;
      };

    },[getCountries, isCountriesloaded]);
       
    return (
        <Box  sx={{height:'100vh',overflow:'scroll'}}>
        <Grid container justifyContent='space-between' direction='column'sx={{backgroundColor:{sm:'#fff',lg:'#fff',padding:{sm:0,lg:1}}}} >
             <Grid item xs={12} sm={12} md={12} lg={12} >
             {
              store ? <Grid container justifyContent='space-between' spacing={2}>
              <Grid item xs={12} >
           
     
             <Storecad  store={store} balance={balance}theme={theme} t={t}/>
     
              </Grid>
              <Grid item xs={12} >
          
          
           <ContentTitleBar store={store} pagetitle={t("form.product.title_prural")} buttons={ [{title:t("transactions.title"),link:`/transactions?`},{title:t("form.product.title_prural"),link:`/product-list?`}]} t={t}/>  
         
           </Grid>
             </Grid>:''
             }
          </Grid>
        {/*<Grid container justifyContent={'space-between'}   >
                  <Grid item padding={1} style={{backgroundColor:'var(--app-font-info)'}}>
                 <Typography variant="body1" style={{fontSize:'0.9rem',padding:'3px',margin:'1px',color:'var(--app-secondary)'}}>We kindly request that you refrain from uploading products that include adult content, harassment, violence, drugs or personal images as product images.</Typography>
                  <Typography variant='body1' fontSize={'0.8rem'} color='#fff'>This test net,Stores Created at mainnet shall require veirfication before allowed to post product !!</Typography>
                </Grid>
     </Grid> */}
          <Grid item xs={12}>
            <Grid container justifyContent='space-between' sx={{backgroundColor:'#fff',paddingLeft:{xs:'16px',sm:'16px',md:1,lg:5},paddingRight:{xs:'16px',sm:'16px',md:1,lg:5}}}>
           
          {/*     <h2 className="client-addProductTitle">Add New Product </h2>
            
            
        <Link to={`/dashboard/products?storeId=${store._id}&storeName=${store.name}&categoryId=${store.categoryId}`}>
          <button className="ProductListButton">Products</button>
          </Link>
 
          </div> */}
           
          
          <Grid item className="addProductFormContaine">
          { store.status==='Verified' ?
           <form className="addProductForm" onSubmit={(e)=>{handleOnFormsubmit(e,clearFields,colors,sizes,name,price,shippingFees,store.categoryId,description,specification,digitalProductUrl,store._id,store.tag,stock,active,base64EncodedImage,productCategory)}}>
           <Grid container justifyContent="space-around" spacing={1} padding={0}>

            {/* Left column start*/}
           <Grid item xs={12} sm={12} md={6} lg={6} >
           <Grid container justifyContent="space-between" direction={'column'} spacing={2} padding={0}  sx={{padding:{sm:0,md:4,lg:4},backgroundColor:{xs:"fff",sm:
          '#fff',md:'whitesmoke',lg:'whitesmoke'},border:{lg:'2px solid var(--app-primary)',borderRadius:2}}}>
       
            <Grid item  xs={12} sm={12} md={12} lg={12}>
           <div className="client-addProductItem">
                <label>{t("newproduct.name.label")}</label>
                <CustomTextField type="text" variant='outlined' required fullWidth className='productameinput' value={name} onChange={(e)=>{setName(e.target.value)}} placeholder={t("newproduct.name.placeholder")}/>
                </div>
             </Grid>
             
               <Grid item  xs={12} sm={12} md={12} lg={12}>
             <div className="client-addProductItem">
              <label htmlFor="validationTextarea">{t("newproduct.description.label")}</label>
               {/*<textarea id="description" name="description" rows="4"
         placeholder= "Describe the product you are selling" value={description}
         required onChange={(e)=>{setDescription(e.target.value)}}></textarea> */}
         { isDesktop ? <TextEditor onEditorStateChange={onEditorStateChange} editorstate={editorstate}/>:
            <>
             <CustomTextField  rows={3} required multiline fullWidth  placeholder={t("newproduct.description.hint")} variant='outlined' style={{border:"1px solid var(--app-secondary)"}} onChange={(e)=>       {setDescription(e.target.value);setDescriptionLength(e.target.value.length)}} value={description} />
          { descriptionLength !==0 ? <>
          {
             descriptionLength < 20 && ( // Example: Check if length exceeds 10 characters  
              <CustomTypography variant="body2" color="red">  
                length must exceed 20 cheracters
              </CustomTypography>  
            )
          }
          </>:''} 
            </>
               
           }
      
         </div> 
          </Grid>
       
              <Grid item key={'product-gallery'} xs={12} sm={12} md={12} lg={12}>
           {/* <ImagesContainer handleImages={handleImages} onSubmit={onSubmit} setOnsubmit={setOnsubmit} clearImagesonSubmit={clearImagesonSubmit}/> */}
            
             <ImageGallery handleImages={handleImages} productImages={productImages} base64EncodedImage={base64EncodedImage} label={t("form.product.gallery.label")}/>
             <div style={{color:'red',backgroundColor:'var(--app-font-info)',padding:'4px',marginTop:4,borderRadius:'2px'}}>
             <CustomTypography color='warning'>{t("form.product.gallery.notice")}</CustomTypography>
             </div>
          </Grid>

     
        </Grid>
         </Grid>{/* Left column end*/}

         <Grid item justifyContent="space-between" xs={12} sm={12} md={4} lg={4}  padding={0} border={1} sx={{  
         paddingRight:{lg:2},paddingLeft:{lg:2},border:{lg:'2px solid var(--app-primary)',borderRadius:2},
        backgroundColor:{sm:'#fff',md:'whitesmoke',lg:'whitesmoke'}
      }} >
         <Box  
      sx={{  
        height: {sm:'auto',lg:"120vh"}, // Set container height  
        overflow: "scroll", // Enable vertical scrolling 
        
      }}  
    >  
           <Grid container justifyContent='flex-start'  marginBottom={5}spacing={1} >
  
           <Grid item  xs={12} sm={12} md={12} lg={12}>
            <label >{t("form.product.price.label")}</label>
           {/*   <input type="number"  placeholder="0.02000000" value={price} required onChange={()=>{setPrice(e.target.value)}} /> */}
             <CustomTextField variant='outlined' type="number" name="price" placeholder="0.1000000" value={price} onChange={(e)=>{setPrice(e.target.value)}} required fullWidth />
           
          </Grid>
          <Grid item  xs={12} sm={12} md={12} lg={12}>
             <label>{t("form.product.shipping.label")}</label>
            {/*  <input type="number"  placeholder="0.00000011" value={shippingFees} required onChange={(e)=>{onshippingFeesChange(e)}} /> */}
             <CustomTextField variant='outlined' type="number" name="shippingFees" placeholder={'0.0000011' /* t("form.product.shipping.placeholder") */} value={shippingFees} fullWidth onChange={(e)=>{onshippingFeesChange(e)}} />
           
             </Grid>
          
           
             <Grid item  xs={12} sm={12} md={12} lg={12}>
             <label>{t("form.product.stock.label")}</label>
             {/* <input type="number" placeholder="10" value={stock} required onChange={onstockChange} /> */}
             <CustomTextField variant='outlined' type="number" name="stock" placeholder={t("form.product.stock.placeholder")} fullWidth value={stock} onChange={onstockChange} required/>
           
             </Grid>

             <Grid item  xs={12} sm={12} md={12} lg={12}>
          

         
         <label htmlFor="validationCustom05">{t("form.product.digital_product.title")}</label>
        
             <Select value={showDigitalProductFileInput? 'yes':'no'} variant='outlined' name="digital_product_Url"  required fullWidth onChange={onDigitalProuctInputChange}
             sx={{  
                            
              "& .MuiOutlinedInput-root": {  
                padding: "4px", // Outer padding adjustment  
              },  
              "& .MuiInputBase-input": {  
                padding: "8px", // Inner padding adjustment  
                fontSize: "12px", // Adjust font size  
                backgroundColor:'#fff',
              },  
            
            }} 
             >
                   <MenuItem key={'digPr-url-yes'} value='no' >No </MenuItem>
                   <MenuItem key={'digPr-url-no'} value='yes' >Yes </MenuItem>

                   </Select> 
            

             </Grid>
            {/*  <Grid item  xs={4} sm={10} md={12} lg={12}>
          
         
           <label htmlFor="validationCustom04">{t("form.product.specification.label")}</label>
          
              <Select value={specification} variant='outlined' name="specification"  required fullWidth onChange={onSpecificationChange}
              
              sx={{  
                "& .MuiOutlinedInput-root": {  
                  padding: "4px", // Outer padding adjustment  
                },  
                "& .MuiInputBase-input": {  
                  padding: "8px", // Inner padding adjustment  
                  fontSize: {sm:"12px",md:"14px",lg:'14px'}, // Adjust font size  
                  backgroundColor:'#fff',

                }, 
              }}  >
                   <MenuItem key={'active-no'} value='yes' >{t("form.product.specification.positive")}</MenuItem>
                   <MenuItem key={'active-yes'} value='no' >{t("form.product.specification.negative")}</MenuItem>
                   </Select> 
              
         
            </Grid>

            */}
             

             <Grid item  xs={12} sm={12} md={12} lg={12} marginLeft={0} >
                
              
            <label >{t("form.product.productlife.label")}</label>
                    
                <Select value={lifespan} variant='outlined' name="lifespan"  required fullWidth  onChange={(e)=>{setLifeSpan(e.target.value)}}
                   sx={{  
                    "& .MuiOutlinedInput-root": {  
                      padding: "4px", // Outer padding adjustment  
                    },  
                    "& .MuiInputBase-input": {  
                      padding: "8px", // Inner padding adjustment  
                      fontSize: "12px", // Adjust font size  
                      backgroundColor:'#fff',

                    },  
                   
                  }}  
                  >
                
                   <MenuItem key={'lifespan-new'}  value='New Product' >{t("form.product.productlife.option1")}</MenuItem>
                   <MenuItem key={'lifespan-used'} value='Used Product' >{t("form.product.productlife.option2")}</MenuItem>

                   </Select> 
               
             </Grid>

              
             <Grid item  xs={12} sm={12} md={12} lg={12}>
             <label>{t("form.product.active.label")}</label>
       
             <Select value={active} variant='outlined' name="active"  required fullWidth  onChange={(e)=>{setActive(e.target.value)}}
               sx={{  
                "& .MuiOutlinedInput-root": {  
                  padding: "4px", // Outer padding adjustment  
                },  
                "& .MuiInputBase-input": {  
                  padding: "8px", // Inner padding adjustment  
                  fontSize: "12px", // Adjust font size 
                  backgroundColor:'#fff',
 
                }, 
              }}  
              >
                   <MenuItem key={'active-no'} value='yes' >{t("form.product.specification.positive")}</MenuItem>
                   <MenuItem key={'active-yes'} value='no' >{t("form.product.specification.negative")}</MenuItem>

                   </Select> 
               
             </Grid>

            
             
             <Grid item  xs={12} sm={12} md={12} lg={12}>
          
          {/*  <div className="client-addProductItem">
         <label htmlFor="validationCustom06">Category</label>
        <select id="validationCustom06" value={selectedCategory} required onChange={onProductSpecsChange}>
           <option value={null}></option>
          {
            productCategories.map((cat,index)=>{
              return  <option value={cat._id} key={index}>{cat.name}</option>
            })
          }
          </select>
    </div> */}
    
   {filStoreProtCategories.length > 0 ? <div className="client-addProductItem">
         <label htmlFor="validationCustom06">{t("form.product.category.label")}</label>
       {/*  <select id="validationCustom06" value={selectedCategory} required onChange={onProductSpecsChange}>
           <option value={null}></option>
          {
             filStoreProtCategories.map((cat,index)=>{
              return  <option value={cat._id} key={index}>{cat.name}</option>
            })
          }
          </select> */}
          <Select value={selectedCategory} variant='outlined' name="country"  required  onChange={onProductCategoryChange} fullWidth 
           sx={{  
           
            "& .MuiOutlinedInput-root": {  
              padding: "4px", // Outer padding adjustment  
            },  
            "& .MuiInputBase-input": {  
              padding: "8px", // Inner padding adjustment  
              fontSize: "12px", // Adjust font size  
              backgroundColor:'#fff',
            },  
          
          }}  
          >
          <MenuItem   value={null}></MenuItem>

                  {filStoreProtCategories.map((cat,index)=>(
                     <MenuItem sx={{fontSize:'0.7rem',margin:0,padding:0}}   key={index} value={cat._id}>
                      <Grid container justifyContent='flex-start'>
                  <Grid item xs={1}>
                  <CategoryIcon category={cat}/>
                  </Grid>
                  <Grid item xs={11}>
                  <Typography>{truncateStringNoSuffix(cat.name,35)}</Typography>
                  </Grid>
                  
              </Grid>
                     </MenuItem>
                 ))}
                 </Select>    
    </div>:''}
          
           </Grid>
        {!showDigitalProductFileInput  ?
           <Grid item  xs={12} sm={12} md={12} lg={12}>
             <label>{` ${t("form.product.shipping.duration.hint")}`}</label>
        {/*
            <CustomTextField variant='outlined' type="text" name="duration" placeholder={t("form.product.shipping.duration.placeholder")} fullWidth value={shippingDuration} onChange={(e)=>{setShippingDuration(e.target.value!==''? parseInt(e.target.value):0)}} required/>
                */}

                  <Box sx={{ display: "flex",border:'1px solid darkgrey',borderRadius:1, flexDirection: "column", gap: 2, maxWidth: 400, mx: "auto",p:1.5}}>
                    <Grid container justifyContent='space-between' spacing={2}>
                        <Grid item xs={6}>
                          
                        <CustomTextField
                                  placeholder="2"
                                  label={t('form.product.shipping.duration.value')}
                                  type="number"
                                  variant="outlined"
                                  value={deliveryTime}
                                  onChange={(e) => setDeliveryTime(e.target.value)}
                                  inputProps={{ min: 1 }}
                                  fullWidth
                              />
                        </Grid>

                        <Grid item xs={6}>
                        <FormControl fullWidth>
                                  <InputLabel>{t('form.product.shipping.duration.unit')}</InputLabel>
                                  <Select value={timeUnit} 
                                  label={t('form.product.shipping.duration.unit')}
                                  onChange={(e) => setTimeUnit(e.target.value)}
                                    sx={{  
                            
                                      "& .MuiOutlinedInput-root": {  
                                        padding: "4px", // Outer padding adjustment  
                                      },  
                                      "& .MuiInputBase-input": {  
                                        padding: "8px", // Inner padding adjustment  
                                        fontSize: "12px", // Adjust font size  
                                        backgroundColor:'#fff',
                                      },  
                                    
                                    }}  >
                                      <MenuItem value="hours">Hours</MenuItem>
                                      <MenuItem value="days">Days</MenuItem>
                                      <MenuItem value="weeks">Weeks</MenuItem>
                                  </Select>
                              </FormControl>
                        </Grid>
                    </Grid>
                              

                              

                            
                          </Box>
             </Grid>
             : ''
          }
             { !showDigitalProductFileInput ?<Grid item  xs={12} sm={12} md={12} lg={12}>
               <Grid container justifyContent='space-between' spacing={2} direction='column'>
               <Grid item xs={12}>
              <CustomTypography  fontSize={{sm:'12px'}}>{t("form.product.specification.label")}</CustomTypography>

               </Grid>
                 
               <Grid item xs={12}>
               <Specs setColors={setColors} setSizes={setSizes} t={t} resetSpecs={resetSpecs} msizes={msizes} mcolors={mcolors} setmColors={setmColors} setmSizes={setmSizes}/>
               </Grid>
             </Grid>
             </Grid> :''}

             <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className="client-addProductItem">
            { showDigitalProductFileInput ?   <div className="digital_product">
               <CustomTypography>{t("form.product.digital_product.hint")}</CustomTypography>
               <div className="client-addProductItem">
               <CustomTextField type="text" id="digital-product-file" placeholder="https://drive.google.com/file/d/1PzOdYqBftPID4BNvUa3T_OzEBkzUBwDT/view?usp=drivesdk" onChange={onDigitalProductUrlChange} />
               </div>
               </div> :<></>}
          
            </div>
          
             </Grid>
             <Grid item xs={12} sm={12} md={12} lg={12}>
               <div className="client-addProductItem">
               <Grid container justifyContent='space-between'>
                <Grid item >
                <label>{t("form.product.countryofsale")}</label>
                </Grid>
               
                <Grid item >
                {showChecklist ?  <ExpandLess  onClick={()=>{/* setShowCheckList(!showChecklist) */}}/>: <ExpandMore onClick={()=>{/* setShowCheckList(!showChecklist) */}}/>}
              
                </Grid>
               </Grid>
               <Grid container >
               <Grid item >
                    {<Typography variant='body2' color='darkgrey' >
                      {
                        checkedCategories.map((item)=>{
                            return `,${item.name}`
                        })
                      }</Typography>}
                </Grid>
               </Grid>
                {
                   showChecklist ? <div className='checkList'>
                   <CheckboxList data={countries} checkedCategories={checkedCategories} setCheckedCategories={setCheckedCategories} />
                 </div>:''
                }
                </div>
               </Grid>



               <Grid item xs={12} sm={12} md={12} lg={12}>
               <div className="client-addProductItem">
               <Grid container justifyContent='space-between' direction='column' spacing={0}>
                <Grid item >
                <label>{t("form.product.shippingarea.label")}</label>
                </Grid>
               <Grid>
                <Typography variant='body2' sx={{fontSize:'0.70rem'}}>{t("form.product.shippingarea.hint")}</Typography>
               </Grid>
               </Grid>
               <Grid container >
               <Grid item >
                    
                </Grid>
               </Grid>
                {
                   <CustomTextField  rows={2} required multiline fullWidth  placeholder= {t("form.product.shippingarea.placeholder")} variant='outlined'  onChange={(e)=>       {setShippingarea(e.target.value)}} value ={shippingarea}/>
                }
                </div>
               </Grid>

             <Grid item xs={12} sm={6} md={12} lg={12} marginTop={3}>
               <div className="client-addProductItem">
              <Button sx={{textTransform:"none"}} className="addProducButton" fullWidth variant='outlined' color='primary' type="submit"  onClick={onAddProductCLick}>{t("newproduct.submit_button")}</Button>

        </div>
             </Grid>
             {/* <Grid item key={'product-na'} xs={6} sm={6} md={6} lg={12}>
         
             </Grid> */}
          </Grid> 
          </Box>
           </Grid>
          
            </Grid>
       
{/* 
             <div className="productFormTop">
                  <div className="productFormTopItem">

               
             

         
       
      </div>
         <div className="productFormTopItem">
       
          
           </div>
        <div className="productFormTopItem">
     
       

          
        
         </div>
        </div> */}
           
            
           
      
        </form>: <Grid container marginLeft={'30%'} marginTop={'50%'} >
                 <Grid item  alignItems='center' justifyContent={'center'}>
                   <Grid container direction='column' rowSpacing={4} justifyContent={'space-around'} alignItems='center'>
                   <Grid item > Store not Verified , please contact <a style={{textDecoration:'none'}} href='/dashboard/help' >support</a>  for assitance</Grid>
                  {/* <Grid item ><Button variant='contained' size='small' onClick={()=>{handleNavigate('/dashboard/help')}}>Contact support</Button> 
                 </Grid>*/}
                   
                   </Grid>
                 </Grid> 
                 <Grid item xs={12} sm={12} md={8} lg={8}>
                 
                 </Grid>
           </Grid>}
       
            </Grid>
            </Grid>

         </Grid>  
       
       </Grid> 

          </Box>    
    )
}
