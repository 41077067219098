import React, { useState, useMemo } from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  ListSubheader,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CustomTextField from "../customComponent/CustomTextField";

const containsText = (text, searchText) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

/* const allOptions = ["Option One", "Option Two", "Option Three", "Option Four"];
 */
export default function CountrySelect({allOptions,defaultOption,selectedCountry,setCountry, setSelectedCountry,variant,onChange,Label,required,open, setOpen,setUsercountryscope,getRecentProductsByscope}) {
 //
  const selectRef = React.useRef(null);
  const userCscopes=[]
  const [searchText, setSearchText] = useState("");
  const displayedOptions = useMemo(
    () => allOptions.filter((option) => containsText(option.name, searchText)),
    [allOptions, searchText]
  );



 
  const handleOnchange =(e)=>{
    console.log(e.target.value)
    console.log(e.target.value.name)

    setSelectedCountry(e.target.value)
    //onChange(e)
   
        userCscopes.push({
          name:e.target.value.name,
          countryCode:e.target.value.iso2
     })

      
      //setUsercountryscope(userCscopes)
      localStorage.setItem('userCountryscope',JSON.stringify(userCscopes))

      console.log(userCscopes)
      //getRecentProductsByscope([e.target.value.name])

  }


  /* const handleClick = () => {
    if (selectRef.current) {
      selectRef.current.openMenu();
    }
  }; */
  //handleClick(selectRef)
  return (
    <Box sx={{ m: 0 ,width:'100%'}}>
             
      <FormControl fullWidth>
      {/*   <InputLabel id="search-select-label">{Label}</InputLabel> */}
        <Select
          ref={selectRef}
          open={open}
          onClose={() => {setOpen(false);setSearchText("")}}
          onOpen={() => setOpen(true)}
          // Disables auto focus on MenuItems and allows TextField to be in focus
          MenuProps={{ autoFocus: false }}
          labelId="search-select-label"
          id="search-select"
          value={selectedCountry}
          label={Label}
          variant={`${variant}`}
          required={required}
          fullWidth
          onChange={(e) =>{ handleOnchange(e)}}
          sx={{  
            "& .MuiOutlinedInput-root": {  
              padding: "4px", // Outer padding adjustment  
            },  
            "& .MuiInputBase-input": {  
              padding: "8px", // Inner padding adjustment  
              fontSize: "12px", // Adjust font size  
            },  
          }}  
         // onClose={() => setSearchText("")}
          // This prevents rendering empty string in Select's value
          // if search text would exclude currently selected option.
          //renderValue={() => selectedOption}
        >
          {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
          <ListSubheader>
            <TextField
              size="small"
              // Autofocus on textfield
              autoFocus
              placeholder="Type to search..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          <MenuItem key={0} value={'All'}>
              {'All'}
            </MenuItem>
          {displayedOptions.map((option, i) => (
            <MenuItem key={i} value={option}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
