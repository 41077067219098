/* eslint-disable no-unused-vars */
import {useEffect, useState,useCallback} from 'react'
import './product-review.css';
import {Link} from "react-router-dom";
import {Button,Grid,TextField,Typography,MenuItem,Select, Tooltip, Card} from '@mui/material';

import {axiosClient,config} from '../../services/axiosClient';
import { convertValueFromExponent, formarttoPiCurrency, replaceItem, truncateString} from "../../utils/Utils"
import { myCld } from '../../cloudinaryImage'
import ImageView from './imageview/ImageView';
import ProductDetail from './ProductDetail';
import ProdcutListTabs from './ProductListTaps';
import ProductList from './ProductList';
import { Products } from '../../components';

export default function ProductReview({setShowProgress,showprogress,authenticateToken,setMessage,setOpenSnackBar,handlepostMessage}) {
 
  const getCheckedCountries= (data,productScopes) =>{
    let checkedCountryscopes = []
   if(data.length >0){
    for(var i=0;i<data.length;i++){
      const obj ={name: data[i].name, countryCode: data[i].iso2}

       for(var j=0;j<productScopes.length;j++){
        if (JSON.stringify(productScopes[j])===JSON.stringify(obj)){
          checkedCountryscopes.push(data[i]) //assign whole country object
        }
       }
    }
  } 
  //console.log(checkedCountryscopes)

    return checkedCountryscopes
  
}


  
     const [product,setProduct]= useState();
     const [products,setProducts]= useState([]);
     const [filteredProducts,setFileteredProducts]= useState([]);
     const [verified,setVerified] = useState(false)
     const [active,setActive]=useState('yes')
     const [storeMessage,setStoreMessage]=useState('')
     const [messageTitle,setStoreMessageTitle]=useState('')
     const [store,setStore]=useState([])
     const [showForms,setShowForms]=useState(true)
     const [isProductsLoaded,setisProductsLoaded]=useState(false);
     const [user]=useState(JSON.parse(localStorage.getItem('user')));

    
      const handleUpdate=(e)=>{
            e.preventDefault();
            setShowProgress(true)
       
            
            editProduct().then((response)=>{
              console.log(user)
               setShowProgress(false)
              
                    
                try{
                 if(response.data.status===200){
                     setProduct(response.data.product)
                     const p =response.data.product
                     const ps=products
                     console.log(products)
                     setProducts([])
                     console.log(replaceItem(ps,p._id,p))

                     setProducts(replaceItem(ps,p._id,p))

                      setMessage({body:'product updated successfully',severity:'success'})
                     setOpenSnackBar(true) /* */
                     console.log({title:messageTitle,content:storeMessage.content})
                     if(messageTitle && storeMessage){
                       handlepostMessage(product.storeId,product._id,{title:messageTitle,content:storeMessage.content,link:storeMessage.link},false)
                     }
                    
                 }else{
                  authenticateToken(user.user)
                 }
               
                }catch(error){
                  console.log(error)
                }

            });
      }

      const editProduct =()=>{
        const url = `${process.env.REACT_APP_SERVER_URL}/products/verify/${product._id}`;
      
        
        const body={
               
                 active:active,
                 verified:verified
                 
        }
        const accessToken =localStorage.getItem('accessToken')
        const config = {
            headers: {
                'auth-token':
                  user.auth_token,
                  Authorization:`Bearer ${accessToken}`

              },
        }
        return axiosClient.patch(url, body,config)
      
      };
      const handlegetStore = useCallback(async (storeId)=>{
        // console.log("get cart"+userid)
        
        getStore(storeId).then((response) => {
             setShowProgress(false)
            if (response.status===200){
              try{
               
                   setStore(response.data.store)
              }catch(err){
                console.log(err)
              }
            }
          })
        
      },[setShowProgress])
      
          const getStore =(storeId)=>{
           
          const url = `${process.env.REACT_APP_SERVER_URL}/stores/${storeId}`;
          
          return axiosClient.get(url,{},config)
        
        };
      const handleSelectProduct =(product)=>{
        setProduct(product)
        handlegetStore(product.storeId)
      }
      const onSearchFieldChange =(e)=>{
        const searchterms = e.target.value
        console.log(searchterms)
        const results = products.filter(prd => prd.name.includes(searchterms));
            console.log(results)
            if (results.length >0 && searchterms !==''){
              setFileteredProducts(results)
            }else if (results.length ===0 && searchterms !==''){
              setFileteredProducts(results)
            }
            else
            {
              setFileteredProducts(products)
            }
      }
        
      useEffect(()=>{

        if (product){
            setProduct(product); 
            setVerified(product.verified)
            setActive(product.active)
        }
        const handlegetProducts = async ()=>{
          // console.log("get cart"+userid)
          setShowProgress(true)

          getProducts().then((response) => {
               setShowProgress(false)
              if (response.status===200){
                try{
                 
                     setProducts(response.data)
                     setFileteredProducts(response.data) //

                    if(response.data.length > 0){
                        setProduct(response.data[0])
                        handlegetStore(response.data[0].storeId)
                    }
                }catch(err){
                  console.log(err)
                }
              }
            })
          
        }
        
            const getProducts =()=>{
             
            const url = `${process.env.REACT_APP_SERVER_URL}/products`;
            
            return axiosClient.get(url,{},config)
          
          };

          if(!isProductsLoaded){
            handlegetProducts()
          }

         return  () =>{
          setisProductsLoaded(true)
         }
      },[handlegetStore, isProductsLoaded, product, setShowProgress])

     
    return (

        <div className="product-review">
           <form  onSubmit={handleUpdate}>

           <Grid container justifyContent='space-between' >
           
            <Grid item xs={4} sm={4} md={4} lg={4} >
             {
             products ?  <ProdcutListTabs showprogress={showprogress} handleSelectProduct={handleSelectProduct} products={products}  setShowProgress={setShowProgress} handlegetStore={handlegetStore} />:''
             }
             
            </Grid>
           {
            product !==undefined && store[0] ?
            <ProductDetail myCld={myCld} product={product} active={active}setActive={setActive} verified={verified} setVerified={setVerified} store={store[0]} setStoreMessage={setStoreMessage} messageTitle={messageTitle} setStoreMessageTitle={setStoreMessageTitle} />:''
           }
       
           </Grid>
             </form>
            <div className="productTop">
           
            </div>
           { showForms ? <div className="pm-productBottom">
               
               
                    
                
                  
            </div>:''}
        </div>
     );
}