import { Button, Grid } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { Countries } from '../../assets/jsonData/countries'
import { useEffect } from 'react'
import CustomTextField from '../customComponent/CustomTextField'
import CountrySelect from './CountrySelect'
import { axiosClient } from '../../services/axiosClient'

const User = ({t,user,setUser,setUsercountryscope,setMessage,setOpenSnackBar,getRecentProductsByscope,handleNext}) => {
   
    
  const[userObj,setUserObj]=useState(JSON.parse(localStorage.getItem('user')))
  //const [nextClaimAvailable, setNextClaimAvailable] = useState(null);  
  const [_stores,set_Stores]=useState([]) 
  const[username,setUsername]=useState('');
  const[firstname,setFirstname]=useState('');
  const[lastname,setLastname]=useState('');
  const[walletAddress,setWalletAddress]=useState('');
  const[walletAddress1,setWalletAddress1]=useState('');
  const[email,setEmail]=useState('');
  const[phone,setPhone]=useState('');
  const[merchantId,setmerchantId]=useState('')
  const[address,setAddress]=useState();
  const[isUserAddress,setisUserAddress]=useState(false);
  const[country,setCountry]=useState(/* user ? user.address.country
: */'' );
  const[image,setImage]=useState('')

  const [imagename,setImageName]=useState('');
  const[onImageChanged,setOnImageChanged]=useState(false)

  const[onuserUpdated,setonuserUpdated]=useState(false);

  const[state,setState]=useState('');
  const[city,setCity]=useState('');
  const[street,setStreet]=useState('');
  const[aprt_suit_num,setApt_suit_num] =useState('');
 
  const [selectedCountry, setSelectedCountry] = useState();
  const[isCountriesloaded,setIscountriesLoaded]=useState(false)
  const [countries,setCountries]=useState([]);


  
  const editUser =()=>{
   
  const url = `${process.env.REACT_APP_SERVER_URL}/user/${user._id}`;
       let address={
         country:selectedCountry.name,
         state:state,
         city:city,
         street:street,
         aprt_suit_num:aprt_suit_num
       }
  const body={
           userId:user._id,
           username:username.toLowerCase(),
           email:email,
           firstname:firstname,
           lastname:lastname,
           phone:phone,
           address:address,
           imagename:imagename
  }
  const config = {
      headers: {
          'auth-token':
            user.auth_token,
        },
  }
  return axiosClient.patch(url, body,config)
  
  };
const UploadStoreImage = (file) => {
    //const url = process.env.STORE_URL;
    
    const formData = new FormData();
      formData.append('imagename',imagename);
      formData.append('image', file);
    
      const config = {
        headers: {
            'Content-Type':'application/json',
            'auth-token': user.auth_token,
          },timeout: 20000, withCredentials: true,credentials: "include"
         
          
    } 
    
    return axiosClient.post(`${process.env.REACT_APP_SERVER_URL}/user/updateImage/${user._id}`, formData, config)
    }

  const handleUpdate=(e)=>{
    e.preventDefault();
    console.log(selectedCountry.name)
    editUser().then((response)=>{
        if(response.status===200){
             setUserObj(response.data)
                setUser(response.data.user)
             /* setUsername(response.data.name);
             setEmail(response.data.email);
             setFirstname(response.data.firstname);
             setLastname(response.data.lastname);
             setPhone(response.data.phone);
             setAddress(response.data.address); */
             localStorage.setItem('user',JSON.stringify(response.data));
           
            setMessage({body:'user updated successfully',severity:'success'})
            setOpenSnackBar(true)             
            setonuserUpdated(!onuserUpdated);
            if (onImageChanged){
                UploadStoreImage(image).then((response) => {
                    if (response.data.status===200){
               setMessage({body:'user image updated',severity:'success'})
               setOpenSnackBar(true) 
               handleNext()
              }
                    //addToast(exampleToast(response.data.message));
                })
            }
            handleNext()
            //history.push('/new-store')

         }else{
             setMessage({body:'oops something happens',severity:'success'})
            setOpenSnackBar(true) 
            }
       

    });
}
    useEffect(()=>{
        /*  setUsername(user.username);
        setFirstname(user.firstname);
        setLastname(user.lastname);
        setWalletAddress(user.walletAddress);
        setWalletAddress1('');
        setEmail(user.email);
        setPhone(user.phone);
        setAddress(user.addresses); */
        if (!isCountriesloaded){
          try{
            setCountries(Countries)
           /*  const url=`${process.env.REACT_APP_SERVER_URL}/countries`;
            await axiosClient.get(url,config).then((response)=>
                  setCountries(Countries)
                 
            ) */
            // console.log(mountedRef.current)
            
            }catch(err){
                console.log(err)
            }
         }
        if (user){
              setUsername(user.username);
               setFirstname(user.firstname);
              setLastname(user.lastname);
              setWalletAddress(user.walletAddress);
              setWalletAddress1('');
               setEmail(user.email);
              setPhone(user.phone);
              setmerchantId(user.merchantId)
              setAddress(user.addresses);
           setSelectedCountry(country ? Countries.filter((c) => c.name === country)[0]:Countries[0])
             //setSelectedCountry(Countries.filter((c) => c.name === user.country)[0])
        }
        
        
            try{
              if (userObj!==null && localStorage.getItem('loggedin' === true)){
                setUser(userObj.user)
               // set_Stores(stores)
    
              
              }
                
    
            }catch(err){
              console.log(err)
            }
          
    
           
            try{
               if (!isUserAddress){
                setAddress(user.address);
                setCountry(country !== '' ? address.country :'');
                setState(address.state!== '' ? address.state :'');
                setCity(address.city!== '' ? address.city :'');
                setStreet(address.street!== ''? address.street :'');
                setApt_suit_num(address.aprt_suit_num!== ''? address.aprt_suit_num :'');
                //setUserImage(`${user.image[0].secure_url}`)
               }
            }catch(err){
                console.log()
            }
            return ()=>{
              setisUserAddress(true)
            }
        },[address, country, isCountriesloaded, isUserAddress, setUser, user, userObj])
  return (
    
                     <Grid container  marginTop={1}className="user-pdateLft" justifyContent={'space-between'} >
                         <Grid item xs={12} >
                          <form  className="client-userUpdateForm" onSubmit={handleUpdate}>
                 
                                 <Grid container justifyContent='space-between' spacing={2}
                                 sx={{spacing:{xs:1,md:1,lg:2},padding:{xs:1,md:1,lg:2}}}
                                 >
                                   {/* <div className="
                                -userUpdateItem">
                                    <label>UserName</label>
                                    <input type="text" required placeholder="mardiaabu33" className="_userUpdateInput" onChange={(e)=>{setUsername(e.target.value)}} value={username}/>
                                </div> */}
                    <Grid item xs={12} className="
                    -userUpdateItem">
                      
                        <CustomTextField fullWidth
                        placeholder={t("form.account.firstname.label")}
                          variant='outlined' 
                          color='primary'
                          type="text" required  
                        /*  onFocus={()=>{handleFocus()}}
                         onBlur={()=>{handleBlur()}} */
                       /*  placeholder="Mardia" */ className="_userUpdateInput" onChange={(e)=>{setFirstname(e.target.value)}} value={firstname}/>
                    </Grid>
                    <Grid item xs={12} className="
                    -userUpdateItem">
                        
                        <CustomTextField 
                        placeholder={t("form.account.lastname.label")}
                        fullWidth variant='outlined' type="text" color='primary' required /* placeholder=" Abubakari" */ className="_userUpdateInput" onChange={(e)=>{setLastname(e.target.value)}} value={lastname}/>
                    </Grid>
                    <Grid item xs={12} className="
                    -userUpdateItem">
                        
                        <CustomTextField 
                        fullWidth
                        placeholder={t("form.account.email.label")}
                         variant='outlined' color='primary' type="email" sx={{fontSize:'0.7rem'}} required /* placeholder="mardiaabu33@gmail.com" */  onChange={(e)=>{setEmail(e.target.value)}} value={email}
                          
                         />
                    </Grid>
                    <Grid item xs={12} className="
                    -userUpdateItem">
                       
                        <CustomTextField placeholder={t("form.account.phone.label")} fullWidth variant='outlined' color='primary' type="number" required /* placeholder="+23358473829"  */className="_userUpdateInput" onChange={(e)=>{setPhone(e.target.value)}} value={phone}/>
                    </Grid>
                    <Grid item xs={12} className="
                    -userUpdateItem">
                        <label>{t("form.account.country.label")}</label>
                        {/* <CustomTextField  variant='outlined' type="text" required placeholder="Ghana" className="_userUpdateInput" onChange={(e)=>{setCountry(e.target.value)}} value={country}/> */ }
                        <CountrySelect required variant='outlined'  setOpen={()=>{}} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} allOptions={countries}  onChange={(e)=>{setCountry(e.target.value.name)}} 
                        setCountry={setCountry}
                        setUsercountryscope={setUsercountryscope} Label='Country' getRecentProductsByscope={getRecentProductsByscope}/>    
                    </Grid>
                    <Grid item xs={12} className="
                    -userUpdateItem">
                       {/*  <label>{t("form.account.state.label")}</label> */}
                        <CustomTextField fullWidth variant='outlined' type="text" color='primary' required placeholder={t("form.account.state.label")}className="_userUpdateInput" onChange={(e)=>{setState(e.target.value)}} value={state}/>
                    </Grid>
                    <Grid item xs={12}className="
                    -userUpdateItem">
                       
                        <CustomTextField
                        placeholder={t("form.account.city.label")}
                        fullWidth variant='outlined' color='primary' type="text" required /* placeholder="Tamale" */ className="_userUpdateInput" onChange={(e)=>{setCity(e.target.value)}} value={city}/>
                    </Grid>
                    <Grid item xs={12} className="
                    -userUpdateItem">
                     
                        <CustomTextField placeholder={t("form.account.street.label")} fullWidth color='primary'  variant='outlined' required /* placeholder="Nsung naa st" */ className="_userUpdateInput" onChange={(e)=>{setStreet(e.target.value)}} value={street}/>
                    </Grid>
                    <Grid item xs={12} className="
                    -userUpdateItem">
                        <CustomTextField fullWidth
                         placeholder={t("form.account.apartment.label")}
                         variant='outlined' type="text" primary='primary' /* placeholder="apertment No,Hno eg E343"  */className="_userUpdateInput" 
                        /*  onFocus={()=>{handleFocus()}}
                         onBlur={()=>{handleBlur()}} */
                        onChange={(e)=>{setApt_suit_num(e.target.value)}} value={aprt_suit_num}/>
                       </Grid>                    
                    
                    {
                    <Grid item xs={12}>
                        <Grid container>
                         {/*  {
                             stores &&  stores.length > 0 ? '': */}<Grid item xs={12}  >
                             <Button type='submit' fullWidth /* disabled={stores.length >0 ? true :false} */ variant='outlined' color='primary'>{t("form.account.buttonupdate.label")}</Button>
                           </Grid>
                         {/*  } */}

                        </Grid>
                    </Grid>
                    }
                                  </Grid> 
                              

                      </form>
                          
                          
                          </Grid>
                      </Grid>
  )
}

export default User
