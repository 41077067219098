/* eslint-disable no-unused-vars */
import React,{useState,useEffect} from 'react'
import {Card,CardMedia,CardActions,Typography,IconButton,Grid, Button} from '@mui/material';

import useStyles from './styles';
import { AddShoppingCart,FavoriteBorderOutlined,Favorite } from '@mui/icons-material';
import {useHistory} from 'react-router-dom';
import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { truncateString,formarttoPiCurrency,formarttoFixedPiCurrency} from '../../../utils/Utils';
import ReactCountryFlag from "react-country-flag"
import { myCld } from '../../../cloudinaryImage'
import { useTranslation } from 'react-i18next';
import usedPorudctIcon from '../../../assets/icons/used_product_128px.png'
import ProductLife from '../product/ProductLife'
import {detect} from 'detect-browser'


const Product = ({product,onAddToCart,onUpdateLikes,favorites,setOpenModal,page}) => {
    const classes=useStyles();
    const history=useHistory();
    const[like,setLike]=useState(false);
    const [t] = useTranslation('common');
    const [width, setWidth] = useState(100);
    const [height, setHeight] = useState(100);
    const [isObserve,setIsObserve]=useState(false)
    const cardRef = React.useRef();
    const loggedin =localStorage.getItem('loggedin');
    const imgid=product.image[0].public_id
    const [browser,setBrowser]=useState()
    try{
      var img = myCld.image(imgid).format('auto').quality('auto').addFlag('lossy').toURL()
      var s3img = product.s3Image[0].url ? product.s3Image[0].url:''
      
    }catch(err){
      console.log(err)
    }
    const theme = createTheme({
  
        palette: {
          primary:{
            main:"#ff9800",
          /*   main:"#6d1b7b", */
          },
            secondary: {
                main:red[500],
                contrastText:'#fff'
            },
            warning:{
              main:red[100],
              contrastText:'#fff'
            }
          },
  });  

  const handleAddtoFavorites =(product)=>{
    if (loggedin==='true'){
      
    setLike(!like);
    onUpdateLikes(product._id,product.storeId);
    }else{
      setOpenModal(true)
    }
  }
 
    const handleProductOnClick=(productid,storeid,storetag)=>{
          
         if (storeid){
         
          //history.push(`/proceedcheckout?storeId=${storeid}&productId=${productid}`)
          history.push(`/s/${storetag}/${productid}`)

         }
     
    }
    const buyNowBtn = (product,qty,action) => {
      try {
        
       if(browser.name!=='chromium-webview') {
         const url = `${process.env.REACT_APP_CLIENT_PINET_URL}/store?storeId=${product.storeId}`
         console.log(url)
         //history.push(url)
         window.location.href = url
    }else{
      onAddToCart(product,qty,action)
    }
      } catch (error) {
       console.log(error)
      }
     }
    const Buynow= ({product})=>(
      <Grid container justifyContent={'flex-end'}  onClick=
                  {()=>{onAddToCart(product,0,'buynow')}}>
        <Grid item ><Typography variant="body2" className={classes.buynow} fontSize={11}  fontWeight={600}>
    Buy now
</Typography></Grid>
         {/*<Grid item >
           <AddShoppingCart  className={classes.icon}/>   
                  </Grid>*/}
      </Grid>
   )
     const FreeTag= ()=>(
        <Grid container justifyContent={'flex-start'}  border={0}>
          <Grid item xs={7}><Typography variant="span"  fontSize={11}>
          {t("form.product.shipping.label")} :
  </Typography></Grid>
          <Grid item xs={5}><Typography variant="span"  fontSize={12} className={classes.freeTag}>
      {t("free")}
  </Typography></Grid>
        </Grid>
     )
      useEffect(() => {

        const b = detect();
        setBrowser(b)
       try{
         // console.log(favorites.includes(product._id))
         if ( favorites.includes(product._id,0)){
          setLike(true)
        }else{
          setLike(false)
        }
       }catch(err){
        console.log(err)
       }
       const resizeObserver = new ResizeObserver((event) => {
        // Depending on the layout, you may need to swap inlineSize with blockSize
        // https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry/contentBoxSize

        setWidth(event[0].contentBoxSize[0].inlineSize);
        setHeight(event[0].contentBoxSize[0].blockSize);
       /*  console.log(`${event[0].contentBoxSize[0].inlineSize} ${event[0].contentBoxSize[0].blockSize}  `) */
      });
  
      if (cardRef) {
        if (!isObserve){
           resizeObserver.observe(cardRef.current);
        }
       
      }

      return ()=>{
         setIsObserve(true)
      }
      },[favorites, setLike, product, isObserve])
  return ( 
    <div>

   {product ? 
    <Card key={product._id} ref={cardRef} className={classes.root} sx={{boxShadow:0}}>
           <Grid container border={0} justifyContent={'flex-end'} >
           <Grid item xs={12}  marginLeft={1} width={width/2.5} height={20} border={0} >
          {
    product.lifespan !=='' ?   <Grid container  /* height={height} */justifyContent='flex-start' border={0} spacing={0.5} >
            <Grid item marginTop={1}>

            { product.lifespan ==='Used Product'?  <ProductLife product={product} variant='outlined'/>
             :''
            
            /*  <Typography fontSize={'0.7rem'} sx={{bgcolor:`${product.life==='New Product'? '#34af4d':'#d39012'}`,padding:0.2}} color='whitesmoke'>{product.lifespan}</Typography>  <img alt='product lifeSpan' src={usedPorudctIcon}  height={25} width={25}/> */
            
            }
            </Grid>
 
            
        </Grid> :'' 
      }
          
            </Grid>

            <Grid item xs={12} border={0} marginTop={-3} >
            <CardMedia className={classes.media}  image={process.env.REACT_APP_WEB_IMAGE_LOCATION ==='s3'? s3img:img} title={product.name} onClick={()=>{handleProductOnClick(product._id,product.storeId,product.storeTag)}}/>
            </Grid>
            </Grid>
         {/*   <AdvancedImage className={classes.media} cldImg={img}/> */}
             <Grid container justifyContent={'space-between'}   flexDirection='column' padding={1} onClick={()=>{handleProductOnClick(product._id,product.storeId,product.storeTag)}}>
              <Grid item  >
               <Grid container justifyContent={'flex-start'}>
                    <Grid item>
                    <Typography variant='span' align='left' color={'GrayText'}  fontSize={12} fontWeight={600} >
                        {truncateString(product.name,22)}
                    </Typography>
                    </Grid>
               </Grid>
              </Grid>

              <Grid item  >
                <Grid container justifyContent='space-between'  alignItems={'center'} /* spacing={0} */ border={0} padding={0}>
                    <Grid item >
                    <Typography variant="span" fontSize={14} className={classes.price}>
                        {`${formarttoPiCurrency(product.price)}`}
                    </Typography>
                    </Grid>
                  
                   {/*  <Grid item >
                    <IconButton aria-label="Add to Favourite" onClick={()=>{ handleAddtoFavorites(product)}}>
                   { like ===true ?  <Favorite className={classes.icon}  />: <FavoriteBorderOutlined  className={classes.icon} /> }
                  </IconButton> 
                    </Grid> */}
                </Grid>
              

              </Grid>

               <Grid container justifyContent='space-between'>
               <Grid item xs={10} >
              {product.shippingFees >= 0.00000001 ? <Typography color={'GrayText'} variant="span" fontSize={11} className={classes.price}>
                        {`${t("form.product.shipping.label")} :${formarttoFixedPiCurrency(product.shippingFees,8)}`}
                    </Typography>:<FreeTag/>}
                </Grid>
                <Grid item xs={2} >
                 
                {/*  <Grid container justifyContent='flex-start' spacing={0.5}>
                 {product.countryScope.map((item,i)=>{
                     return <Grid item ><ReactCountryFlag countryCode={item.countryCode} svg
                         
                     style={{
                      width: '1em',
                      height: '1em',
                  }}/></Grid>
                })  }
                 </Grid> */}
                          {page ==="store" ?  <Grid item >
                            {
                              page==='store' ? <IconButton aria-label="Add to Cart" onClick=
                              {()=>{buyNowBtn(product,0,'buynow')}}>
                                <AddShoppingCart color='primary'   className={classes.icon}/>  
                              </IconButton>:''
                            }
                    </Grid> : <Grid container justifyContent='flex-end' spacing={0.5}>
                 {/* product.countryScope.map((item,i)=>{
                     return <Grid item ><ReactCountryFlag countryCode={item.countryCode} svg
                         
                     style={{
                      width: '1em',
                      height: '1em',
                  }}/></Grid>

                })  */ }
                 </Grid>
                 
                 }
        
                </Grid>
               </Grid>
             </Grid> 
              {/* 
             </Grid>
             
              <CardContent  className={classes.cardContent}>
                  <div className={classes.cardContentSub}>
                   
                    
                   
                  </div>
                  <div className={classes.description}>
                  <Typography  dangerouslySetInnerHTML={{__html:truncateString(product.description,54)}} variant="body2" color="textSecondary" noWrap={false}/>  
                  </div>
              </CardContent> 
              <CardActions disableSpacing padding={1}  >
            <IconButton aria-label="Add to Favourite" onClick={()=>{ handleAddtoFavorites(product)}}>
                   { like ===true ?  <Favorite className={classes.icon} />: <FavoriteBorderOutlined className={classes.icon} /> }
                  </IconButton> 
                    <IconButton>
                      <Buynow  product={product} />
                  </IconButton>
                 
                  
                  <IconButton aria-label="Add to Cart" onClick=
                  {()=>{onAddToCart(product,0,'addtocart')}}>
                    <AddShoppingCart  className={classes.icon}/>  
                  </IconButton>

              </CardActions>
              <Grid container justifyContent={'space-between'}>
                  <Grid item xs={2}>
                    <IconButton aria-label="Favorites" onClick={()=>{ handleAddtoFavorites(product)}}>
                    { like ===true ?  <Favorite className={classes.icon} />: <FavoriteBorderOutlined className={classes.icon} /> }  
                      </IconButton>  
                  </Grid>
                  <Grid item xs={8} >
                    <IconButton>
                      <Buynow  product={product} />
                  </IconButton>
                 
                  </Grid>

                  <Grid item xs={2}>
                  <IconButton aria-label="Add to Cart" onClick=
                  {()=>{onAddToCart(product,0,'addtocart')}}>
                    <AddShoppingCart  className={classes.icon}/>  
                  </IconButton>
                  </Grid>
              </Grid>*/}
        </Card>
   :''}
    </div>
  )
}

export default Product

