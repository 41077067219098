/* eslint-disable no-unused-vars */
import React,  {useState,useEffect} from 'react'
import {LocationCityOutlined, LocationSearching, MailOutline, PhoneAndroid} from '@mui/icons-material';
import {ShoppingBagOutlined,SellOutlined,Visibility,VisibilityOff,PaymentOutlined,CheckOutlined,Storefront,MessageOutlined,MessageSharp} from '@mui/icons-material';
import {Grid,Button,Box,Badge} from '@mui/material';
import './user.css'
import {useHistory} from 'react-router-dom';
import {axiosClient}from '../../services/axiosClient';
import Avatar from '../../assets/icons/user_96px.png';
import useStyles from './styles';
import AlertDialog from './alertdialog/AlertDialog'
import Verifyalert from '../productManager/widraw/alert/verifyalert/Verifylert'
import {truncateString,checkAttendance, getDeviceType} from '../../utils/Utils'
import { Countries } from '../../assets/jsonData/countries';
import CountrySelect from './CountrySelect';
import { useTranslation } from 'react-i18next';
import NotificationWidget from '../notification/Notification';
import AppCrediteDailyclaim from './AppCrediteDailyclaim';
import CustomTextField from '../customComponent/CustomTextField';
import CustomTypography from '../customComponent/CustomTypography';
import Login from './Login'
const Account = ({stores,user,setUser,loggedin,setLoggedin,setStore,setOrder,setCart,appcredits,setMessage,setOpenSnackBar,authenticateToken,handleLogOut,signIn,emailverified,handleSendConfirmationCode,handleConfirmedUser,setEmailVerified,showprogress,setShowProgress,visited,setVisited,claimedReward,updateAppcredits,setUsercountryscope,getRecentProductsByscope,notification,showNotification,setShowNotification,handleVisitsClaim,nextClaimAvailable, setNextClaimAvailable,handleFocus, handleBlur}) => {
   //console.log(`${showNotification} ${JSON.stringify(notification)}`)
        //console.log(nextClaimAvailable)

   const classes = useStyles()
   const [deviceType]=useState(getDeviceType()) //tablet mobile desktop

  const[userObj,setUserObj]=useState(JSON.parse(localStorage.getItem('user')))
  //const [nextClaimAvailable, setNextClaimAvailable] = useState(null);  
  const [_stores,set_Stores]=useState([]) 
  const[username,setUsername]=useState('');
  const[firstname,setFirstname]=useState('');
  const[lastname,setLastname]=useState('');
  const[walletAddress,setWalletAddress]=useState('');
  const[walletAddress1,setWalletAddress1]=useState('');
  const[email,setEmail]=useState('');
  const[phone,setPhone]=useState('');
  const[merchantId,setmerchantId]=useState('')
  const[address,setAddress]=useState();
  const[isUserAddress,setisUserAddress]=useState(false);
  const[country,setCountry]=useState(/* user ? user.address.country
: */'' );
  const[state,setState]=useState('');
  const[city,setCity]=useState('');
  const[street,setStreet]=useState('');
  const[aprt_suit_num,setApt_suit_num] =useState('');
  const[image,setImage]=useState('')
  const [imagename,setImageName]=useState('');
  const [userImage,setUserImage]=useState('')
  const[onuserUpdated,setonuserUpdated]=useState(false);
  const[onImageChanged,setOnImageChanged]=useState(false)
  const[showEdit,setShowEdit]=useState( true);
  const[isAccessTokenRefreshed,setAccessTokenRefresh]=useState(false)
  const[isShownNotification,setIsSetNotification]=useState(false)
  const[open,setOpen]=useState(false)
  const [openconfirmation,setOpenconfirmation]=useState(false);
  const[visibility,setVisiblity]=useState(deviceType === 'mobile'?false:true)
  const [selectedCountry, setSelectedCountry] = useState();
  const history=useHistory();
  const[isCountriesloaded,setIscountriesLoaded]=useState(false)
  const [countries,setCountries]=useState([]);
  
  console.log(stores)
  const [t] = useTranslation('common');
  const imgonLoadError=(e)=>{
   e.target.src = Avatar
}
const dialogRef = React.createRef()

  const handleClickOpen = () => {
      
    setOpen(true)

 };
 const handleClose = (option) => {
      
  setOpen(false);
  setOpenconfirmation(false)
};

const handleSignIn =() =>{
  var scopes =["username","payments","wallet_address"]
  signIn(scopes)
}
const widgetPanel = ()=>{
  return(
    <Grid container justifyContent='space-between' alignContent={'flex-start'}  spacing={2} direction='column'>
    <Grid item xs={12}>
      {
    notification &&  notification.length > 0 ?<Grid>
      {
        notification.map((n,i)=>{
            return <NotificationWidget notification={n} />
        })
      }
        
    </Grid>:''
      }
    </Grid>
 </Grid>
  )
}

// Handle claim action  
/* const handleClaim = async (useruid) => {  
  try {  
    const response = await axiosClient.post(`${process.env.REACT_APP_SERVER_URL}/appcredits/visitsclaim/${useruid}`);  

    console.log(response)
    
   // setPoints(response.data.points);  
    setNextClaimAvailable(new Date(response.data.nextClaimAvailable));  
    //setError("");  
  } catch (err) {  
    //setError(err.response?.data?.message || "An error occurred.");  
    console.log(err.response?.data?.message || "An error occurred.")
  }  
}; 
 */
const Rewards = ({appcredits,visited})=>{
  return(
   
     <Grid  container justifyContent='space-between'  padding={1} className='reward'>
                     <Grid item xs={4}>
                       <Grid container justifyContent='space-between' direction='column' alignItems='center'> 
                     
                       <Grid item onClick={()=>{}} >
                       <CustomTypography  variant='h6' color='white'>{appcredits !==undefined ? appcredits.recievedOrders:0}</CustomTypography>
                        </Grid>
                         <Grid item alignContent={'center'} padding={0}>
                           <CustomTypography  fontWeight={500} variant='body2' color='white'>{t("account.rewards.confirmrecieveds")}</CustomTypography>
                         </Grid>
                        
                       </Grid>
                     </Grid>
                     
                   <Grid item xs={4}>
                       <Grid container justifyContent='space-between' direction='column' alignItems='center' color='white'> 
                       <Grid item onClick={()=>{}}>
                       <CustomTypography variant='h6' color='white'>{appcredits!==undefined ? appcredits.comments:0}</CustomTypography>
                         
                         </Grid>
                         <Grid item>
                           <CustomTypography  variant='body2' color='white'>{t("account.rewards.comments")}</CustomTypography>
                         </Grid>
                        
                       </Grid>
                     </Grid> 
                     <Grid item xs={4}>
                       <Grid container justifyContent='space-between' direction='column' alignItems='center' > 
                       <Grid item>
                       <CustomTypography variant='h6' color='white'>{appcredits!==undefined ?appcredits.visits:0}</CustomTypography>
                         </Grid>
                         <Grid item onClick={()=>{}}>
                           <CustomTypography  variant='body2' color='white'>{t("account.rewards.dailyrewards")}</CustomTypography>
                         </Grid>
                        {
                          loggedin ==="true" ? <Grid item marginTop={1}><>
                           {nextClaimAvailable > new Date()  
            ? <CustomTypography color='primary' fontSize={'0.6rem'}>{nextClaimAvailable.toLocaleString()} </CustomTypography> 
            :  <Button className='client-action-Button' sx={{zIndex:3}} variant="outlined" color="primary" onClick={()=>{/* updateAppcredits(2,'visits'); */handleVisitsClaim(user.uid)}}>{t("account.rewards.claimbtn")}</Button>}  
                          </>
                          {/* claimedReward === 'false' ?:'' */}
                           </Grid>:''
                        }
                       </Grid>
                     </Grid>

                  
                  </Grid>
  )
  }

function handleSubmit (e){
   e.preventDefault();
  if (walletAddress===walletAddress1){
   handleUpdateWallet(user._id,walletAddress)
  }
}
function onFileInputChange(e) {
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = function (e) {
        document.getElementById('avatar').src = e.target.result;
         document.getElementById('user-image').src = e.target.result;
       
        setOnImageChanged(true);
     //console.log(file)
     setImage(file);
     //use user to name uer image 
     var filename=username+'.'+file.name.split('.').pop();
     //console.log(filename);
     setImageName(filename);
    };

    try {
        reader.readAsDataURL(file)

    } catch (error) {
        console.log({ readAsDataURLError: error })
    }
}
 /*const onSrchCountryChange =(e)=>{
  console.log(e.target)
  const country=e.target.value;
  const cid=country.id;
  //const name=country.name;
  const countryObject={
    name:country.name,
    countryCode:country.iso2
  }   
  setCountry(country.name)
  //setShippingFees(parseFloat(0.00000020))
}
 const handleLogOut =()=>{
     localStorage.setItem('user', '');
    localStorage.setItem('loggedin',false); 
    localStorage.clear();
    setCart([])
    setOrder([])
    setStores([])   
    history.push('/');

    //window.location.reload()
    
  }*/
  const handleNavigatePage = (path)=>{
    //navigate to product page
   history.push(path);

}
const navigateNewStorepage=()=>{
  history.push('/new-store')

 /*  if (user.address){
    history.push('/new-store')
  }else{
    setMessage({body:'Update your profile first and try again ',severity:'success'})
    setOpenSnackBar(true)
    setVisiblity(!visibility)
  } */
}
const handleVerifyEmail = (email)=>{
  setOpenconfirmation(true)
   /* console.log(email)
    const url = `${process.env.REACT_APP_SERVER_URL}/email`;

  axiosClient.post(url,{email:email}).then((response)=>{
      console.log(response)
      setMessage({body:'confirmation email is sent to your inbox ',severity:'info'})
      setOpenSnackBar(true)
  }) */
}
const handleUpdateWallet = async (_id,walletAddress)=>{
  const url = `${process.env.REACT_APP_SERVER_URL}/auth/walletaddress/update`;

  const config = {
    headers: {
        'Content-Type':'application/json',
      },timeout: 20000, withCredentials: true,credentials: "include"
     
      
} 
    axiosClient.patch(url,{userId:_id,walletAddress:walletAddress},config).then((response)=>{
      if (response.data.status===200){
        setOpen(false)
      }else{
        setWalletAddress('')
      }
    });
 } 
const handleUpdate=(e)=>{
    e.preventDefault();
    console.log(selectedCountry.name)
    editUser().then((response)=>{
        if(response.status===200){
             setUserObj(response.data)
                setUser(response.data.user)
             /* setUsername(response.data.name);
             setEmail(response.data.email);
             setFirstname(response.data.firstname);
             setLastname(response.data.lastname);
             setPhone(response.data.phone);
             setAddress(response.data.address); */
             localStorage.setItem('user',JSON.stringify(response.data));
           
            setMessage({body:'user updated successfully',severity:'success'})
            setVisiblity(!visibility)
            setOpenSnackBar(true)             
            setonuserUpdated(!onuserUpdated);
            if (onImageChanged){
                UploadStoreImage(image).then((response) => {
                    if (response.data.status===200){
               setMessage({body:'user image updated',severity:'success'})
               setOpenSnackBar(true) 
                    
              }
                    //addToast(exampleToast(response.data.message));
                })
            }
            history.push('/new-store')

         }else{
             setMessage({body:'oops something happens',severity:'success'})
            setOpenSnackBar(true) 
            }
       

    });
}

const handleOpenStorePage = ()=>{
  setStore(stores[0]);
  history.push('/my-store')

}
const UploadStoreImage = (file) => {
//const url = process.env.STORE_URL;

const formData = new FormData();
  formData.append('imagename',imagename);
  formData.append('image', file);

  const config = {
    headers: {
        'Content-Type':'application/json',
        'auth-token': user.auth_token,
      },timeout: 20000, withCredentials: true,credentials: "include"
     
      
} 

return axiosClient.post(`${process.env.REACT_APP_SERVER_URL}/user/updateImage/${user._id}`, formData, config)
}

const editUser =()=>{
 
const url = `${process.env.REACT_APP_SERVER_URL}/user/${user._id}`;
     let address={
       country:selectedCountry.name,
       state:state,
       city:city,
       street:street,
       aprt_suit_num:aprt_suit_num
     }
const body={
         userId:user._id,
         username:username.toLowerCase(),
         email:email,
         firstname:firstname,
         lastname:lastname,
         phone:phone,
         address:address,
         imagename:imagename
}
const config = {
    headers: {
        'auth-token':
          user.auth_token,
      },
}
return axiosClient.patch(url, body,config)

};
    useEffect(()=>{
    /*  setUsername(user.username);
    setFirstname(user.firstname);
    setLastname(user.lastname);
    setWalletAddress(user.walletAddress);
    setWalletAddress1('');
    setEmail(user.email);
    setPhone(user.phone);
    setAddress(user.addresses); */
    if (!isCountriesloaded){
      try{
        setCountries(Countries)
       /*  const url=`${process.env.REACT_APP_SERVER_URL}/countries`;
        await axiosClient.get(url,config).then((response)=>
              setCountries(Countries)
             
        ) */
        // console.log(mountedRef.current)
        
        }catch(err){
            console.log(err)
        }
     }
    if (user){
          setUsername(user.username);
           setFirstname(user.firstname);
          setLastname(user.lastname);
          setWalletAddress(user.walletAddress);
          setWalletAddress1('');
           setEmail(user.email);
          setPhone(user.phone);
          setmerchantId(user.merchantId)
          setAddress(user.addresses);
       setSelectedCountry(country ? Countries.filter((c) => c.name === country)[0]:Countries[0])
         //setSelectedCountry(Countries.filter((c) => c.name === user.country)[0])
    }
    
    
        try{
          if (userObj!==null && localStorage.getItem('loggedin' === true)){
            setUser(userObj.user)
            set_Stores(stores)

          
          }
            

        }catch(err){
          console.log(err)
        }
      

        if (!isAccessTokenRefreshed && loggedin ==='true'){
          authenticateToken(user)
          setVisited(checkAttendance(false))

        }
        try{
           if (!isUserAddress){
            setAddress(user.address);
            setCountry(country !== '' ? address.country :'');
            setState(address.state!== '' ? address.state :'');
            setCity(address.city!== '' ? address.city :'');
            setStreet(address.street!== ''? address.street :'');
            setApt_suit_num(address.aprt_suit_num!== ''? address.aprt_suit_num :'');
            setUserImage(`${user.image[0].secure_url}`)
           }
        }catch(err){
            console.log()
        }
        return ()=>{
          setAccessTokenRefresh(true)
          setisUserAddress(true)
        }
    },[address, appcredits, authenticateToken, country, isAccessTokenRefreshed, isCountriesloaded, isUserAddress, loggedin, setLoggedin, setNextClaimAvailable, setUser, setVisited, stores, user, userObj, visited])
     
  return (
        <Box className="" sx={{ height: '100vh',overflow:'scroll', width: '100%'}}>
    <Grid container className={classes.root} sx={{paddingLeft:{xs:'16px',sm:'16px',md:1,lg:5},paddingRight:{xs:'16px',sm:'16px',md:1,lg:5}}} xs={12} sm={12} md={12} lg={12} >
       { /*notification && notification.length > 0 ?  <FloatComponent Component={widgetPanel} setIsVisible={true}  isVisible={true} /> : ''*/}
       <AlertDialog dialogRef={dialogRef}  open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} title="Update Wallet Address " textContent={`Please Login to continue  `} positiveButtonText={'Update'}negativeButtonText={'Cancel'} handleSubmit={handleSubmit} walletAddress={walletAddress} setWalletAddress={setWalletAddress} setWalletAddress1={setWalletAddress1} />
       {
      user ?  <Verifyalert open={openconfirmation} user={user} handleClose={handleClose} 
      title={t("alerts.verification_needed")}  showprogress={showprogress} setShowProgress={setShowProgress} handleSendConfirmationCode={handleSendConfirmationCode} handleConfirmedUser={handleConfirmedUser} setEmailVerified={setEmailVerified} t={t}/> :''
     }
     
     {  loggedin ==='true'?  <Grid container justifyContent='space-around' spacing={0} sx={{paddingTop:{xs:1,sm:1,md:0,lg:5}}} > 

<Grid item xs={12} sm={12} md={12} lg={12} >

        <Grid container justifyContent='space-between' direction='column'  >
       
                 
            <Grid item border={0} xs={12} sm={12} md={12} lg={12}>
              <Grid container justifyContent={'flex-start'} spacing={0.5}     alignItems={'flex-start'}>
                
                <Grid xs={2} lg={0.9} item border={0} height={{xs:60,sm:60,md:80,lg:60}}>
                <img src={userImage}  id="avatar"  onError={imgonLoadError} alt="" className="userShowImg"/>
                </Grid>
             
             
                <Grid item xs={6} lg={7} border={0}height={{xs:60,sm:60,md:60,lg:60}} >
                  <Grid container justifyContent={'flex-start'} direction='column'>
                   {
                     <Grid item xs={12}>
                    {
                      
                     loggedin ==='true'? <CustomTypography variant='h5' fontSize={'0.8rem'} >{`${username!==undefined? `@${username}` :''}`}</CustomTypography> : <Button   variant='outlined' sx={{textDecoration:'none',marginTop:2}}  color='primary' onClick={()=>{handleSignIn()}} className='client-action-Button'>{t("account.logIn")}</Button>
                    }

                   </Grid>
                   }
                    <Grid item xs={12}>
                    <CustomTypography variant='h6'   color='GrayText'>{`${firstname !==undefined ?firstname:''} ${lastname !==undefined ?lastname:''}`}</CustomTypography>
                    </Grid>
                    
                  </Grid>
                </Grid>

                {
                  user && stores ?<Grid item border={0} xs={2} md={2} lg={2} height={{xs:60,sm:60,md:60,lg:60}}>
                     <Grid container  justifyContent={'flex-end'} >
                     {
                     stores.length > 0 ?  
                     <Grid item >
                  
                { merchantId !=='' ?
                <Grid container justifyContent={'flex-end'}  direction='column'>
               <Grid item > 
                <CustomTypography variant='h5' cursor='pointer' color='GrayText'>{t("account.merchantid")}
                </CustomTypography>
               </Grid> 
                <Grid item  > <CustomTypography   fontSize={'0.6rem'}  variant='h6' color={'primary'} onClick={()=>{handleNavigatePage(`/product-list`)}}>{merchantId} </CustomTypography></Grid>

               
                </Grid>:''}
               
                  </Grid>:''
               }
                     </Grid>
                  </Grid> :''
                }


                <Grid item  xs={2} lg={2} border={0} height={{xs:60,sm:60,md:60,lg:60}}>
                     <Grid container justifyContent={'flex-end'}  paddingRight={1}>
                     {loggedin ==='true' ?   <Grid item cursor='pointer' onClick={()=>{setShowNotification(!showNotification)}} > {
                          notification.new_messages_Length >=1 ?<Badge xs={{minWidth:'10px !important',marginTop:'5px !important',top:'8px'}}  className={classes.navBadge} badgeContent={notification.new_messages_Length}  color="primary" variant='outlined' ><MessageOutlined cursor='pointer' color='textDark'/></Badge>:<MessageOutlined cursor='pointer' color='textDark'/>
                        } </Grid>:''}
                     </Grid>
                </Grid>
                  
                </Grid>
                  {/* <div className="userShowTopTitle">
                 <span className="userShowUsername"></span> 
                  <span className="active">{`@${merchantId}`}</span>
                 <span className="userShowUserTitle"></span>
                  </div> */}
                   </Grid>
               <Grid item >
               <Grid container justifyContent={'space-between'}>
                  {/* <Grid item xs={12}>
                    {
                  notification &&  notification.length > 0 ?<Grid>
                    {
                      notification.map((n,i)=>{
                          return <NotificationWidget notification={n} />
                      })
                    }
                      
                  </Grid>:''
                    }
                  </Grid> */}
               </Grid>
               </Grid>
               <Grid item >
                <Box >
                 {
                  loggedin ==='true' && appcredits ?  
                <Rewards appcredits={appcredits} visited={visited}/>
                 :  ''
            
                  }
                </Box>
           
                 {/*<AppCrediteDailyclaim useruid={user.uid}/> */}
              </Grid>
                {/*  <Grid item>
               {
              deviceType==='mobile' ?  <Edit onClick={()=>{setShowEdit(!showEdit)}}/>:''
                }
                </Grid> */}
             </Grid>

     
      
             <Grid item marginTop={5} >
              <Grid container justifyContent={'center'} padding={2}>
               {
              stores ? <Grid item >
              {
                stores.length >0 ? ''
                
                :<Button sx={{textTransform:'none'}}  variant='outlined' color='primary' onClick={()=>{navigateNewStorepage()}}>{t("account.applyasmerchant")}</Button>
              }
               </Grid>:''
                }
              </Grid>
             </Grid>
     
    
    {
        showEdit ?     <Grid className="userShowBottom " item  marginTop={2}>

       {loggedin ==='true' ? <Grid container padding={2} className='cardOutlined'>

              {/*   <span className="userShowTitle">Account Details</span> */}
        {/* <div className="userShowInfo">
             <PermIdentity className="userShowIcon"/>
        <span className="userShowInfoTitle">{`${firstname !==undefined ?firstname:''} ${lastname !==undefined ?lastname:''}`}</span>
        </div> */}
      
      {/*   <div className="userShowInfo">
             <AccountBalanceWallet className="userShowIcon"/>
        <span className="userShowInfoTitle" onClick={()=>{setOpen(!open)}}>{`  ${walletAddress === ''?'Update Wallet Address':truncateString(walletAddress,28)}`}</span>
        </div> */}
      {/*   <span className="userShowTitle">Contact Details</span> */}


        {address ? <Grid item >
              <Grid container>
                

        <Grid item xs={12} >
            <Grid container justifyContent={'flex-start'} alignItems={'center'} spacing={1}>
              <Grid item >
             {phone ? <PhoneAndroid color='textDark' sx={{fontSize:'1rem'}}/>:''}
              </Grid>
              <Grid item >
        <CustomTypography variant='body1' align='left' color='GrayText'>{phone}</CustomTypography>
              </Grid>
            </Grid>
        </Grid>

        <Grid item  xs={12} >
          <Grid container justifyContent={'flex-start'} alignItems={'flex-start'} spacing={1}>
            <Grid item xs={10} > 
          <Grid container justifyContent={'flex-start'} sx={{fontSize:'1rem'}} alignItems={'center'} spacing={1} >
          <Grid item > 
             { email ?<MailOutline color='textDark' sx={{fontSize:'1rem'}}/>:''}
            </Grid>
             <Grid item >
             <CustomTypography  color={'GrayText'}>{truncateString(email,28)}</CustomTypography>
             </Grid>
             </Grid>
             </Grid>
         
          {
            user ? <Grid item xs={2}>
            <Grid container justifyContent={'center'} alignItems='center'>
              {
                user.email ? <Grid item >
                {emailverified ?<CheckOutlined  color="success"/> :  <Button   variant='contained'  color='Completed' onClick={()=>{handleVerifyEmail(user.email)}}  className='client-action-Button'>{t("account.verify")}</Button> }
                </Grid>:''
              }
            </Grid>
        </Grid>:''
          }
         </Grid>
        </Grid>

        <Grid item  xs={12}>
            <Grid container justifyContent={'flex-start'} alignItems={'center'} spacing={1}>
              <Grid item >
             { state ? <LocationSearching color='textDark' sx={{fontSize:'1rem'}} />:''}
              </Grid>
             {
              user ?  <Grid item >
            
              
              {
               address ?  <Grid container justifyContent='space-between'>

                        <Grid item >
                        {
                        country ? <CustomTypography  color={'GrayText'}>{country} </CustomTypography>:''}
                        </Grid>

                        <Grid item >
                        {
                        state ? <CustomTypography  color={'GrayText'}>{state} </CustomTypography>:''}
                        </Grid>
                        <Grid item >
                        {
                        city ? <CustomTypography  color={'GrayText'}>{city} </CustomTypography>:''}
                        </Grid>
                        <Grid item >
                        {
                        street ? <CustomTypography  color={'GrayText'}>{street} </CustomTypography>:''}
                        </Grid>
                        <Grid item >
                        {
                        aprt_suit_num ? <CustomTypography  color={'GrayText'}>{aprt_suit_num} </CustomTypography>:''}
                        </Grid>

               </Grid>:''
              }

             </Grid>:''
             }
            </Grid>
        </Grid>
              </Grid>
          </Grid>:''}
       
        </Grid>:''}

        
       
       
        {
         stores && stores.length > 0 ? <Grid className={`${classes.storedetails} cardOutlined`}>
          {/*  <span className="userShowTitle">Store Details</span>*/} 
            
               <Grid container justifyContent={'space-between'} direction='column' padding={2}>

                  <Grid item >
                    <Grid container justifyContent={'flex-start'} alignItems={'center'} >
                        <Grid item xs={1}>
                        <Storefront color='textDark'  sx={{  
                        fontSize: {  
                          xs: "1rem", // Default for extra-small screens  
                          sm: "1.5rem", // Default for small screens  
                          md: "1.8rem", // Default for medium screens  
                          lg: "2rem",  // Default for large screens  
                        },  
                      
                      }} />

                        </Grid>
                        <Grid item xs={11}>
                  <CustomTypography fontSize={'0.8rem'} textTransform={'none'} color='textGrey' onClick={()=>{handleOpenStorePage()}} >{stores[0] !== undefined ? stores[0].name :''}</CustomTypography>
                        </Grid>
                    </Grid>
                   
                  </Grid>

                  <Grid item >
                <Grid container marginBottom={1} marginTop={1} height={50} maxHeight={100} overflow={'scroll'}>
                <Grid item  xs={12}>
                <CustomTypography variant='body2' align='left' fontSize={'0.8rem'} color='GrayText'>{stores[0] !==undefined ? stores[0].description :''}</CustomTypography> 
                </Grid>
              </Grid>
                  </Grid>
               </Grid>
             
              <Grid container justifyContent='space-between' paddingLeft={2} paddingRight={2}>
                 <Grid item >
                   <Grid container justifyContent='space-between' direction='column' alignItems='center' spacing={0.5}> 
                 
                   <Grid item onClick={()=>{handleNavigatePage(`/product-list`)}}>
                     <SellOutlined color='secondary' style={{transform:'scale(1.3)'}} className={classes.storetapIcon}/>
                     
                     </Grid>
                     <Grid item>
                       <CustomTypography  variant='body2'>{t("account.myproducts")}</CustomTypography>
                     </Grid>
                    
                   </Grid>
                 </Grid>
                 <Grid item >
                   <Grid container justifyContent='space-between' direction='column' alignItems='center' spacing={0.5}> 
                   <Grid item>
                     <ShoppingBagOutlined color='secondary' style={{transform:'scale(1.3)'}} className={classes.storetapIcon}/>
                     
                     </Grid>
                     <Grid item onClick={()=>{handleNavigatePage(`/transactions`)}}>
                       <CustomTypography  variant='body2'>{t("account.orders")}</CustomTypography>
                     </Grid>
                    
                   </Grid>
                 </Grid>

               <Grid item >
                   <Grid container justifyContent='space-between' direction='column' alignItems='center' spacing={0.5}> 
                   <Grid item onClick={()=>{/* handleNavigatePage(`/withdraw`) */}}>
                     <PaymentOutlined disabled={true} style={{transform:'scale(1.3)'}} color='secondary' className={classes.storetapIcon}/>
                     
                     </Grid>
                     <Grid item>
                       <CustomTypography  variant='body2'>{t("account.withdraw")}</CustomTypography>
                     </Grid>
                    
                   </Grid>
                 </Grid> 
                {/*  <Grid item >
                   <Grid container justifyContent='space-between' direction='column' alignItems='center'> 
                   <Grid item onClick={()=>{handleNavigatePage(`/new-store`)}}>
                     <LocalShippingOutlined color='primary' className={classes.storetapIcon}/>
                     
                     </Grid>
                     <Grid item>
                       <CustomTypography variant='body2'>Shipping</CustomTypography>
                     </Grid>
                    
                   </Grid>
                 </Grid> */}
              </Grid>
              <br/><br/> 
             </Grid>:''
         }
         
       
       
       
       </Grid>:''
    }

</Grid>
<Grid item xs={12} sm={4} md={12} lg={12} xl={12} marginTop={2}>
 
     {
          loggedin ==='true'  && showEdit ? <Grid container  className={`${classes.userUpdatWrapper} cardOutlined`}> 

           <Grid item xs={12} >
             {loggedin ==="true" ? <Grid container justifyContent='space-between' spacing={1} sx={{padding:{xs:1,md:1,lg:2}}}>
            <Grid item xs={8}>
               <CustomTypography variant='body1' color='GrayText'>{t("account.info")}</CustomTypography>
               </Grid>

             <Grid item xs={4}className={classes.editbar}  onClick={()=>{
              setVisiblity(!visibility)
            }}>
              <Grid container justifyContent='flex-end'  >
             <Grid item  > { 
              visibility ? <Visibility color='textDark'/> : <VisibilityOff color='textDark'/> 
              }</Grid>
              </Grid>
              
               </Grid>
            
            </Grid> :''}
           </Grid>
         
          
          {
            visibility ? <Grid container  marginTop={1}className="user-pdateLft" justifyContent={'space-between'} >
                 <Grid item xs={12} >
                  <form  className="client-userUpdateForm" onSubmit={handleUpdate}>
         
                         <Grid container justifyContent='space-between' spacing={2}
                         sx={{spacing:{xs:1,md:1,lg:2},padding:{xs:1,md:1,lg:2}}}
                         >
                           {/* <div className="
                        -userUpdateItem">
                            <label>UserName</label>
                            <input type="text" required placeholder="mardiaabu33" className="_userUpdateInput" onChange={(e)=>{setUsername(e.target.value)}} value={username}/>
                        </div> */}
            <Grid item xs={12} className="
            -userUpdateItem">
              
                <CustomTextField fullWidth
                placeholder={t("form.account.firstname.label")}
                  variant='outlined' 
                  color='primary'
                  type="text" required  
                /*  onFocus={()=>{handleFocus()}}
                 onBlur={()=>{handleBlur()}} */
               /*  placeholder="Mardia" */ className="_userUpdateInput" onChange={(e)=>{setFirstname(e.target.value)}} value={firstname}/>
            </Grid>
            <Grid item xs={12} className="
            -userUpdateItem">
                
                <CustomTextField 
                placeholder={t("form.account.lastname.label")}
                fullWidth variant='outlined' type="text" color='primary' required /* placeholder=" Abubakari" */ className="_userUpdateInput" onChange={(e)=>{setLastname(e.target.value)}} value={lastname}/>
            </Grid>
            <Grid item xs={12} className="
            -userUpdateItem">
                
                <CustomTextField 
                fullWidth
                placeholder={t("form.account.email.label")}
                 variant='outlined' color='primary' type="email" sx={{fontSize:'0.7rem'}} required /* placeholder="mardiaabu33@gmail.com" */  onChange={(e)=>{setEmail(e.target.value)}} value={email}
                  
                 />
            </Grid>
            <Grid item xs={12} className="
            -userUpdateItem">
               
                <CustomTextField placeholder={t("form.account.phone.label")} fullWidth variant='outlined' color='primary' type="number" required /* placeholder="+23358473829"  */className="_userUpdateInput" onChange={(e)=>{setPhone(e.target.value)}} value={phone}/>
            </Grid>
            <Grid item xs={12} className="
            -userUpdateItem">
                <label>{t("form.account.country.label")}</label>
                {/* <CustomTextField  variant='outlined' type="text" required placeholder="Ghana" className="_userUpdateInput" onChange={(e)=>{setCountry(e.target.value)}} value={country}/> */ }
                <CountrySelect required variant='outlined'  setOpen={()=>{}} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} allOptions={countries}  onChange={(e)=>{setCountry(e.target.value.name)}} 
                setCountry={setCountry}
                setUsercountryscope={setUsercountryscope} Label='Country' getRecentProductsByscope={getRecentProductsByscope}/>    
            </Grid>
            <Grid item xs={12} className="
            -userUpdateItem">
               {/*  <label>{t("form.account.state.label")}</label> */}
                <CustomTextField fullWidth variant='outlined' type="text" color='primary' required placeholder={t("form.account.state.label")}className="_userUpdateInput" onChange={(e)=>{setState(e.target.value)}} value={state}/>
            </Grid>
            <Grid item xs={12}className="
            -userUpdateItem">
               
                <CustomTextField
                placeholder={t("form.account.city.label")}
                fullWidth variant='outlined' color='primary' type="text" required /* placeholder="Tamale" */ className="_userUpdateInput" onChange={(e)=>{setCity(e.target.value)}} value={city}/>
            </Grid>
            <Grid item xs={12} className="
            -userUpdateItem">
             
                <CustomTextField placeholder={t("form.account.street.label")} fullWidth color='primary'  variant='outlined' required /* placeholder="Nsung naa st" */ className="_userUpdateInput" onChange={(e)=>{setStreet(e.target.value)}} value={street}/>
            </Grid>
            <Grid item xs={12} className="
            -userUpdateItem">
                <CustomTextField fullWidth
                 placeholder={t("form.account.apartment.label")}
                 variant='outlined' type="text" primary='primary' /* placeholder="apertment No,Hno eg E343"  */className="_userUpdateInput" 
                /*  onFocus={()=>{handleFocus()}}
                 onBlur={()=>{handleBlur()}} */
                onChange={(e)=>{setApt_suit_num(e.target.value)}} value={aprt_suit_num}/>
               </Grid>                    
            
            {
            <Grid item xs={12}>
                <Grid container>
                  {
                     stores &&  stores.length > 0 ? '':<Grid item xs={12}  >
                     <Button type='submit' fullWidth /* disabled={stores.length >0 ? true :false} */ variant='outlined' color='primary'>{t("form.account.buttonupdate.label")}</Button>
                   </Grid>
                  }

                </Grid>
            </Grid>
            }
                          </Grid> 
                      

              </form>
                  
                  
                  </Grid>
              </Grid>:''
          }
                  
           
                </Grid>   :''
        }
</Grid>

<Grid item xs={12} sm={4} md={12} lg={12} marginBottom={8}>
        <hr/>  
        <Grid container justifyContent={'flex-start'} padding={1} spacing={2}>
            <Grid item>
              
            {loggedin ==='true' ? <Button sx={{textDecoration:'none'}} variant={'outlined'} color='primary' className='client-action-Button'onClick={()=>{handleLogOut(user._id)}}>{t("account.logout")}</Button>: <Button  sx={{testDecoration:'outlined'}}variant='outlined' color='primary' className='client-action-Button' onClick={()=>{handleSignIn()}}>{t("account.logIn")}</Button>
             }
             </Grid> {/* {
            user ?:''
           } */}
            <Grid item>
            <Button  sx={{textDecoration:'none'}} variant='outlined' color='primary' onClick={()=>{handleNavigatePage(`/privacy`)}} className='client-action-Button'>{t("account.privacy")}</Button>
            </Grid>
        </Grid>
  </Grid>
</Grid>

:  <Grid item xs={12} sm={12} md={6} lg={6}>
   <Login handleNavigatePage={handleNavigatePage} handleSignIn={handleSignIn} />
    </Grid>}
    
              
    </Grid>
    </Box>
  )
}

export default Account
