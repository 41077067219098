import { Grid,Card,CardMedia} from '@mui/material'
import React from 'react'
import { myCld } from '../../../cloudinaryImage'

const CategoryIcon = ({category}) => {

    const icon= myCld.image(category.icon.public_id).format('auto').quality('auto').addFlag('lossy')
  return (
    <Grid container justifyContent={'center'} alignItems={'center'} direction={'column'} spacing={0} border={0} >
                <Grid item  xs={12} >
              {/*   <img url={`${cat.icon.secure_url}`} alt='category img' style={{backgroundColor: "transparent",height:'50px',width:'50px'}} className={classes.catIcon} /> */}
                 <Card  elevation={0}  /*style={{backgroundColor: "transparent"}}  */> 
                     <CardMedia  className={'catIcon'} /* style={{backgroundColor: "transparent"}}*/ image={icon.toURL()}/> 
                   </Card>
                </Grid>
       
                
               
            </Grid>
  )
}

export default CategoryIcon
